import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

function MapManager()
{
    if (!(this instanceof MapManager)) 
    {
        return new MapManager(mapManager);
    }
    
    var self = this;

    self.Current_Position_Map;
    self.Current_Position_Map_Loaded = false;
    self.Current_Position_Marker;

    self.Geocoder;

    self.Start_Zoom = 12;
    self.Default_Zoom = 16;
    self.Max_Zoom = 16;

    self.Current_Address_Id = 0;
    self.Current_Location;
    self.Current_Address;

	self.Street_Number_Is_Set=false;
    self.Init_Current_Position_Map = function() 
    {
        self.Current_Position_Map = new google.maps.Map(document.getElementById("current-position-map"), 
        {
            center: { lat: 43.143465, lng: 12.203832 },
            zoom: self.Start_Zoom,
            disableDefaultUI: true
        });

        self.Current_Position_Marker = new google.maps.Marker(
        {
            map: self.Current_Position_Map,
            draggable: true,
            animation: google.maps.Animation.DROP
        });

        google.maps.event.addListener(self.Current_Position_Marker, 'dragend', self.Drag_End);
        
        self.Geocoder = new google.maps.Geocoder();
    }

    self.Init_AutoComplete = function() 
    {
        // Create the search box and link it to the UI element.

        let _input = document.getElementById('input-current-position-address');

        let _autocomplete = new google.maps.places.Autocomplete(_input);

        // Bias the SearchBox results towards current map's viewport.

        _autocomplete.bindTo('bounds', self.Current_Position_Map);

        // Set the data fields to return when the user selects a place.

        _autocomplete.setFields(['address_components', 'geometry', 'name']);

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.

        _autocomplete.addListener('place_changed', function() 
        {
            let _place = _autocomplete.getPlace();
			
			//controlla se è impostato il numero civico
			let _address_components=_place.address_components;
			console.log("passo")
			for(let _i = 0; _i < _address_components.length; _i++){ 
				 if((_address_components[_i].types!='undefined') && (_address_components[_i].types).includes("street_number")){
					 console.log("civico impostato "); 
					 self.Street_Number_Is_Set=true;
					 break;
				 }
			} 
			
            if(typeof _place.geometry === 'undefined')
            {
                console.log("Returned place contains no geometry");
                return;
            }

            const _pos = 
            {
                lat: _place.geometry.location.lat(),
                lng: _place.geometry.location.lng()
            };

            self.Current_Address = $$('#input-current-position-address').val();
            self.Current_Location = _pos;

            self.Current_Position_Marker.setPosition(_pos);

            self.Current_Position_Map.setCenter(_pos);
            self.Current_Position_Map.setZoom(self.Max_Zoom);

            $$('#map-container').css('display', 'block');
            $$('#confirm-current-position-address').css('display', 'block');
            
            $$('.confirm-current-location').css('display', 'block');   
			$$('#detected-position-text').css('display', 'block');			
        });
    }

    self.Geocode_LatLng = function() 
    {
        let _marker_position = self.Current_Position_Marker.getPosition();

        self.Geocoder.geocode({ location: _marker_position }, (results, status) => 
        {
            if (status === "OK") 
            {
                if (results[0]) 
                {
                    var _marker_address = results[0].formatted_address;

                    const _pos = 
                    {
                        lat: _marker_position.lat(),
                        lng: _marker_position.lng()
                    };

                    self.Current_Address = _marker_address;
                    self.Current_Location = _pos;

                    $$('#input-current-position-address').val(_marker_address);

                    $$('.confirm-current-location').css('display', 'block');
					$$('#detected-position-text').css('display', 'block');
                } 
            } 
            else 
            {
				if(status != "OVER_QUERY_LIMIT")
                {					
					f7app.dialog.alert("Geocoder failed due to: " + status);
				}
            }
        });
    }

    self.Get_Current_Location = function()
    {
        if (navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(function(position) 
            {
                const _pos = 
                {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

				google.maps.event.addListener(self.Current_Position_Marker, 'dragend', self.Drag_End);
				
                self.Current_Position_Marker.setPosition(_pos);

                self.Current_Position_Map.setCenter(_pos);
                self.Current_Position_Map.setZoom(self.Default_Zoom);

                self.Geocode_LatLng();

                $$('#map-container').css('display', 'block');
                $$('#confirm-current-position-address').css('display', 'block');
            }, 
            function() 
            {
                f7app.dialog.alert("Non è stato possibile rilevare la posizione attuale, ti consigliamo di scrivere l'indirizzo utilizzando l'apposita casella", "Attenzione");
            });
        } 
        else 
        {
            f7app.dialog.alert("Non è stato possibile rilevare la posizione attuale, ti consigliamo di scrivere l'indirizzo utilizzando l'apposita casella", "Attenzione");
        }
    }

    self.Drag_End = function()
    {
        self.Geocode_LatLng();
    }

    self.Get_LatLng_By_Address = function(address, callback) 
    {
        self.Geocoder = new google.maps.Geocoder();

        self.Geocoder.geocode({'address' : address}, function(results, status) 
        {
            if (status == 'OK') 
            {
                let _location = 
                {
                    'lat' : results[0].geometry.location.lat(),
                    'lng' : results[0].geometry.location.lng(),
                };

                if(typeof callback !== 'undefined')
                {
                    callback(_location);
                }
            } 
            else
            {
                f7app.dialog.alert('Indirizzo non valido!');
            }
        });
    }

    self.Get_Address_Data = function(address, callback)
    {
        var _request_params = 
        {
            'address' : address,
            'session_token' : appCore.session_token
        };
        
        f7app.request(
        {
            url: api_url + 'customer_get_address_data.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (addressData) 
            {  
                console.log('address data', addressData);

                if(typeof callback !== 'undefined')
                {
                    let _location = 
                    {
                        'lat' : addressData.data.latitude,
                        'lng' : addressData.data.longitude,
                    };

                    callback(addressData.data.id, _location);
                }
            }
        });
    }
}

export default MapManager;