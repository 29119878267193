
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      user_logged = appUtilities.user_is_logged();

      if (user_logged == false) {
        return;
      }

      load_saved_addresses(_app, _self);
      load_past_orders(_app, _self);
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: 'd82ca8fd69',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page id=current-position-address-page data-name=current-position-address style="background-color: #fff;"><div class=page-content style=padding-top:0px;><div id=current-position-section id=map-container class=card style="min-height:97%; margin:0px;"><div class=card-content><!--<div id="current-position-map" class="no-fastclick" style="height: 300px; background-size: 100% 300px; background-repeat: no-repeat; background-image:url(';
      r += Template7Helpers.js.call(ctx_1, 'img_url', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += 'static_images/home_cover.jpg); ">--><div id=current-position-map class=no-fastclick style="height: 300px; background-position: top; background-image: url(';
      r += Template7Helpers.js.call(ctx_1, 'img_url', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += 'static_images/home_cover.jpg)"></div><div class="card ombra" style="background:var(--f7-theme-color); padding:5px; margin: -25px auto 0px auto; width:80%;"><div class=card-content><p style="color:white; text-align:center; margin:0px">Inserisci l\'indirizzo di consegna:</p><!-- \r\n					NB: La form serve per disattivare la visualizzazione del \r\n					autocomplete di default del browser!!!\r\n					--><form id=form-current-position-address style=margin-top:-10px;><input autocomplete=off placeholder="Indirizzo di consegna" id=input-current-position-address name=input-current-position-address-name type=text style="height:35px; color:#000; font-size:1em; display:inline-block; padding-right:30px;"><i class=f7-icons style="font-size:20px; margin-left:-25px;margin-top:20px; color:var(--f7-theme-color);" id=current_location>location</i></form></div></div><p id=detected-position-text style="display:none; margin: 10px; text-align:center; font-size:11px;">La posizione rilevata non è esatta? Sposta il segnaposto rosso sulla mappa nella posizione giusta! Cliccando su "conferma", l\'ordine verrà consegnato in corrispondenza del segnaposto</p><button id=confirm-current-position-address class="col button button-small button-fill confirm-current-location" style="min-height: 30px; width:50%; margin: 10px auto; display: none;">Conferma</button><div class=web-footer-link2 style="margin-top: 15px;"><a href="https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it"><img height=90% src=./static/images/androidmarketIcona.png onclick=\'location.assign("https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it")\'></a><a href=https://apps.apple.com/app/id1591974126><img height=90% src=./static/images/appleStoreIcona.png onclick=\'location.assign("https://apps.apple.com/app/id1591974126")\'></a></div><!-- Slider container --><div class=row style="align-items: center;">';
      r += Template7Helpers.js_if.call(ctx_1, " this.data != null ", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col-5><i class=f7-icons style="font-size: 20px;" id=left-slide>chevron_left</i></div><div class=col-90><div style="width: 100%; font-weight:bold; margin-top:10px; font-size:1.3em; color: #000000; display: flex; justify-content: center; align-items: center;" class=title-restaurant>Ordini conclusi: ripetili con un click!</div><div id=swiper class=swiper-container><!-- Slides wrapper --><div class=swiper-wrapper><!-- Slides --> ';
          r += Template7Helpers.js_if.call(ctx_2, "typeof this.data !== 'undefined'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.data.order_data, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=swiper-slide><!-- Indirizzo di consegna --><div class=card data-order-id=';
                  r += c(ctx_4.order_id, ctx_4);
                  r += ' style="border-radius:20px; box-shadow: 7px 7px 7px #777777;border: 1px solid #000; padding-top: 10px; background-color: #f4f4f4; opacity: 0.9;"><div class=row><div class=col-30><img src="';
                  r += Template7Helpers.js.call(ctx_4, 'restaurants_img_url', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += c(ctx_4.image, ctx_4);
                  r += '" style="padding: 10px; width: 70px; height: 70px; margin-left:16px; border-radius: 10px;"></div><div class=col-70 style=padding-left:10px;><div class=item-content><div class=item-inner><div class=item-title style="color:var(--f7-theme-color);  font-weight:bold"><span style=font-size:22px;>';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.resturant_name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span></div><p style="font-weight:bold; font-size:19; margin:3px 0px 0px 0px;line-height:1;">';
                  r += c(ctx_4.order_number, ctx_4);
                  r += ' [ ';
                  r += Template7Helpers.js_if.call(ctx_4, " this.order_status == 10 ", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' Respinto dal ristorante ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' Accettato ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ]</p><p style="font-weight:bold; font-size:19; margin:3px 0px 0px 0px;line-height:1;">';
                  r += c(ctx_4.order_date, ctx_4);
                  r += '</p></div></div></div></div><div class="list media-list"><ul><li><div class=item-content style=min-height:auto><div class=item-inner style="padding:0px px 0px 0px; min-height:auto;"><div class=item-title style="color:var(--f7-theme-color); font-size:17; font-weight:bold">Consegna in:</div><div class=item-subtitle style="white-space: pre-wrap; font-size:12px; text-overflow:ellipsis; overflow:hidden;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.delivery_address, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></div></div></li></ul></div><!-- Card header --><div class=card-header style="font-weight: bold;font-size:17;">Prodotti ordinati</div><!-- Card body --><div class="card-content card-content-padding"><div class="list media-list"><ul>';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.items, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><div class=item-content style=min-height:auto><div class=item-inner style="padding:0px 16px 0px 0px;"><div class=item-title-row><div class=item-title style="font-style: italic; margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold; font-size:14px;">';
                      r += c(ctx_5.item_qty, ctx_5);
                      r += ' x ';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div><div class=item-after>';
                      r += c(ctx_5.item_total, ctx_5);
                      r += '&euro;</div></div><div class=item-subtitle style="margin-left: 10px; font-style: italic; height: auto; white-space: pre-wrap;">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.ingredients_description, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div><div class=item-text></div></div></div></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</ul></div><div class="list inset" style="border:1px solid var(--f7-theme-color); margin:20px 1px 1px 1px;"><ul><li><div class=item-content style=min-height:auto><div class=item-inner style="padding:0px 16px 0px 0px;margin:0px; min-height:auto;"><div class=item-title>Totale prodotti:</div><div class=item-after>';
                  r += Template7Helpers.js.call(ctx_4, "appCore.financial(this.item_total)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' &euro;</div></div></div></li><li><div class=item-content style=min-height:auto><div class=item-inner style="padding:0px 16px 0px 0px; min-height:auto;"><div class=item-title>Totale spedizione:</div><div class=item-after>';
                  r += Template7Helpers.js.call(ctx_4, "appCore.financial(this.shipping_cost)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' &euro;</div></div></div></li><li><div class=item-content style=min-height:auto><div class=item-inner style="padding:0px 16px 0px 0px; min-height:auto;"><div class=item-title style="color:var(--f7-theme-color); font-size:19px;">TOTALE ORDINE:</div><div class=item-after style="color:var(--f7-theme-color); font-size:19px;">';
                  r += Template7Helpers.js.call(ctx_4, "appCore.financial(this.grand_total)", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' &euro;</div></div></div></li></ul></div></div></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><!-- Pagination, if required --><div class=swiper-pagination></div></div></div><div class=col-5><i class=f7-icons style="font-size:20px; margin-left:-16px;" id=right-slide>chevron_right</i></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, " this.data == null ", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div style="width: 100%; margin-left: 10px; font-weight:bold; margin-top:20px; font-size:1.3em; color: #000000; display: flex; justify-content: center; align-items: center;" class=title-restaurant>Ordini conclusi: ripetili con un click!</div><div class="card ombrabordo" style="background:#E4E4E4; width:100%; padding:25px 0px;"><div class=card-content style=text-align:center;><img src=./static/images/jacknb_icona.png style=width:60px;></div><div class="card-header no-hairlines" style="font-weight: bold;font-size:14px;color:black; display: flex; justify-content: center; align-items: center;">Non hai ancora effettuato nessun ordine</div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div>';
      return r;
    }(this);
  },

  style: `
.pac-container 
{
	z-index:20000 !important;
}

.centerMarker
{
	position:absolute;
	/*url of the marker*/
	background:url(http://maps.gstatic.com/mapfiles/markers2/marker.png) no-repeat;
	/*center the marker*/
	top:50%;left:50%;

	z-index:1;
	/*fix offset when needed*/
	margin-left:-10px;
	margin-top:-34px;
	/*size of the image*/
	height:34px;
	width:20px;

	cursor:pointer;
}

.ombra{
	box-shadow: 7px 7px 7px #777777;	
}

.ombrabordo{
  border-radius:25px;
	box-shadow: 7px 7px 7px #777777;
	margin:5px;
}

`,
  styleScoped: false
};

function load_saved_addresses(app) {
  var _request_params = {
    'session_token': appCore.session_token
  };
  app.request({
    url: api_url + 'customer_address_listing.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (savedAddressesData) {
      let _success = parseFloat(savedAddressesData.success);

      let _msg = savedAddressesData.msg;
      console.log("saved addresses", savedAddressesData);

      if (_success == 1) {
        if (savedAddressesData.data.length > 0) {//$$('#input-current-position-address').val(savedAddressesData.data[0]["address"]);
        }

        var autocompleteDropdownSimple = app.autocomplete.create({
          openerEl: '#input-current-position-map',
          inputEl: '#input-current-position-address',
          openIn: 'dropdown',
          closeOnSelect: true,
          source: function (query, render) {
            var results = [];

            for (var i = 0; i < savedAddressesData.data.length; i++) {
              if (savedAddressesData.data[i]["address"].toLowerCase().indexOf(query.toLowerCase()) >= 0 && query.length < 1) {
                results.push(savedAddressesData.data[i]["address"]);
              }
            } // Render items by passing array with result items


            render(results);
          },
          on: {
            change: function (value) {
              $$("#current-position-address").focus().trigger("click");

              for (var i = 0; i < savedAddressesData.data.length; i++) {
                if (savedAddressesData.data[i]["address"] == value) {
                  let _selected_address = savedAddressesData.data[i]["address"];
                  mapManager.Get_Address_Data(_selected_address, function (id, location) {
                    if (isStreetNumberSet(_selected_address)) {
                      mapManager.Current_Address_Id = id;
                      mapManager.Current_Address = _selected_address;
                      mapManager.Current_Location = location;
                      ViewHome.router.navigate('/restaurants/');
                    } else {
                      //f7app.dialog.alert('Indirizzo non completo, controlla che sia specificato anche il numero civico!', 'Attenzione', function(){});

                      /*f7app.dialog.confirm('Sembra che nell\'indirizzo specificato manchi il numero civico, vuoi proseguire comunque?','Attenzione', function () {
                      	
                      });*/
                      f7app.dialog.create({
                        title: 'Attenzione',
                        text: 'Sembra che nell\'indirizzo specificato manchi il numero civico',
                        buttons: [{
                          text: 'Inserisci il civico'
                        }, {
                          text: 'Continua senza il civico'
                        }],
                        onClick: function (dialog, index) {
                          if (index === 1) {
                            mapManager.Current_Address_Id = id;
                            mapManager.Current_Address = _selected_address;
                            mapManager.Current_Location = location;
                            ViewHome.router.navigate('/restaurants/');
                          }
                        },
                        verticalButtons: true
                      }).open();
                    }

                    document.activeElement.blur();
                    $$(".pac-container").css("visibility", "hidden");
                    $$(".toolbar").css("display", "block");
                  });
                  /*
                  mapManager.Get_LatLng_By_Address(_selected_address, function(location)
                  {
                  	mapManager.Current_Address_Id = savedAddressesData.data[i]["id"];
                  	mapManager.Current_Address = savedAddressesData.data[i]["address"];
                  	mapManager.Current_Location = location; 
                  			console.log('location ', mapManager.Current_Location);
                  			ViewHome.router.navigate('/restaurants/');
                  			document.activeElement.blur()
                  			$$(".pac-container").css("visibility", "hidden");
                  	$$(".toolbar").css("display", "block");
                  });
                  */

                  break;
                }
              }
            },
            open: function (value) {
              $$(".toolbar").css("display", "none");
              /*
              NB: L'autocomplete in schermi desktop sborda perché lui calcola la proprietà "left" rispetto al viewport
              	e non al parent! Quindi essendo la pagina comunque a schermo intero ma ristretta in una colonna sola,
              	il left non sarà mai giusto perché prende la dimensione della pagina!!
              */

              let _parent_pos = document.getElementById('current-position-address-page').getBoundingClientRect();

              let _input_pos = document.getElementById('input-current-position-address').getBoundingClientRect();

              let _relative_pos = {};
              _relative_pos.top = _input_pos.top - _parent_pos.top, _relative_pos.right = _input_pos.right - _parent_pos.right, _relative_pos.bottom = _input_pos.bottom - _parent_pos.bottom, _relative_pos.left = _input_pos.left - _parent_pos.left;
              console.log("relative pos", _relative_pos);
              $$('.autocomplete-dropdown').css('left', _relative_pos.left + "px");
            },
            opened: function (value) {},
            close: function (value) {
              $$(".toolbar").css("display", "block");
            }
          }
        });
      }
    },
    error: function () {
      app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
    }
  });
}

function load_past_orders(app, self) {
  var _request_params = {
    'session_token': appCore.session_token
  };
  app.request({
    url: api_url + 'customer_past_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (pastOrdersData) {
      console.log("customer past orders data", pastOrdersData);

      let _success = parseFloat(pastOrdersData.success);

      if (_success == 1) {
        self.$setState({
          data: {
            order_data: pastOrdersData.data
          }
        });
        document.arrive("#swiper", function () {
          window.past_orders_swiper = app.swiper.create('#swiper', {
            speed: 200,
            spaceBetween: 5,
            allowSlideNext: true
          });
          console.log('swiper', past_orders_swiper);
        });
      }
    },
    error: function () {
      app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
    }
  });
}

function isStreetNumberSet(address) {
  let pattern = /\d{1,4}/g;
  let result = pattern.test(address);
  return result;
}
    