function AppCore(initCallback)
{
    let self = this;

    this.session_token = '';
    this.waiting_orders;
    this.restaurant_id = 0;
    this.there_is_order_to_pay = false;
    this.previous_orders_count = -1;
    this.thereIs_order_to_pay = false;
    this.paying_order = false;

    if(typeof initCallback !== 'undefined')
    {
        window.appCore = self;
        
        initCallback();
    }

    this.current_restaurant_data =
    {
        id : 0,
        name : '',
        address : '',
        image : '',
        cover_image : '',
        latitude : '',
        longitude : '',
        phone : '',
        opening_hours : [],
        closing_day : '',
        partner_info : '',
        online_payment_enabled : false
    };

    this.current_order_data =
    {
        instructions : '',
        delivery_mode : 'fast-way',
        pre_order_selection : '',
        payment_mode : 'cash'
    };

    this.current_profile_data = 
    {
        'name' : '',
        'surname' : '',
        'email' : '',
        'phone_number' : ''
    };

    this.financial = function(value)
    {
        return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
    }
}

export default AppCore;
