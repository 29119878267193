
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      user_logged = appUtilities.user_is_logged();

      if (user_logged == false) {
        ViewProfile.router.navigate('/login/');
      }
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      user_logged = appUtilities.user_is_logged();

      if (user_logged == false) {
        return;
      }

      var _request_params = {
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'customer_profile.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (customerProfileData) {
          console.log("customer profile data", customerProfileData);
          appCore.current_profile_data.name = customerProfileData.data.name;
          appCore.current_profile_data.surname = customerProfileData.data.surname;
          appCore.current_profile_data.email = customerProfileData.data.email;
          appCore.current_profile_data.phone_number = customerProfileData.data.phone_number;

          _self.$setState({
            data: customerProfileData.data
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageTabShow: function (e, page) {
      //scelgo se mostra i link per scaricare l'app
      if (f7app.device.cordova) {
        $$('.web-footer-link2').remove();
        console.log("rimuovi i link per scaricare l'app");
      }
    }
  },
  id: '546f82480b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class="navbar no-shadow"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=/app-info/ class=link>Info app</a></div><div class=title></div><div class=right><a id=logout href=# class=link>Esci</a></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=red-box style=height:80px;></div><div class=card style="width:50%;margin:-40px auto 0px auto;"><div class="card-content card-content-padding" style="text-align:center; font-weight:bold;"><p>Profilo</p><img src=./static/images/profilo.svg style="width:20px; margin:0;"><div class="card no-border no-shadow"><div class=card-content style="text-align: center"><a href=# id=delete-account>Elimina account</a></div></div></div></div><div class=block-title><p style="margin:0px; font-size:18px; padding-bottom:5px; color:var(--f7-theme-color); text-align:center; border-bottom:solid 1px var(--f7-theme-color);">I miei dati</p></div><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Nome</span></div><div class=item-input-wrap><input id=profile-name type=text placeholder=Nome value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"> <span class=input-clear-button></span></div></div><div class=item-media style=align-self:center><img src=./static/images/matita.svg style="width:20px; margin:0;"></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Cognome</span></div><div class=item-input-wrap><input id=profile-surname type=text placeholder=Cognome value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.surname, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"> <span class=input-clear-button></span></div></div><div class=item-media style=align-self:center><img src=./static/images/matita.svg style="width:20px; margin:0;"></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Telefono</span></div><div class=item-input-wrap><input id=profile-phone-number type=text placeholder=Telefono value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.phone_number, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></div></div><div class=item-media style=align-self:center><img src=./static/images/matita.svg style="width:20px; margin:0;"></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Email</span></div><div class=item-input-wrap><input id=profile-email type=text placeholder=Email value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.email, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" readonly=readonly></div></div></li></ul></div><button id=update-profile class="col button button-small button-fill" style="width:200px; margin: 0 auto; min-height: 30px; margin-top: 20px;">Salva</button><div class=card><div class="card-content card-content-padding" style=text-align:center><a href=/help/ ><img src=./static/images/tasto_aiuto.png style="width:30px; margin:0 2px;vertical-align:middle"> <span>Hai bisogno di assistenza? Clicca qui!</span></a></div></div><div class=web-footer-link2><a href="https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it"><img height=90% src=./static/images/androidmarketIcona.png onclick=\'location.assign("https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it")\'></a><a href=https://apps.apple.com/app/id1591974126><img height=90% src=./static/images/appleStoreIcona.png onclick=\'location.assign("https://apps.apple.com/app/id1591974126")\'></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  style: `
	.red-box{
		background:var(--f7-theme-color);
		color:white;
	}



`,
  styleScoped: false
};
    