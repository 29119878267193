
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
var months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
var days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
var today = new Date();
var orderDate = today.toLocaleDateString("it-IT");
var calendar;
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      calendar = null;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      orderDate = appUtilities.replaceAll(orderDate, "/", "-");

      if (appCore.paying_order == true) {
        // Si è tornati indietro con il tasto indietro del dispositivo
        // ed è rimasto appeso il pagamento Stripe
        // Annulla l'ordine
        appCore.paying_order = false;
        var _request_params = {
          'order_id': appCore.order_id,
          'session_token': appCore.session_token
        };
        f7app.request({
          url: api_url + 'customer_cancel_order.php',
          dataType: 'json',
          data: _request_params,
          method: 'POST',
          cache: false,
          crossDomain: true,
          success: function (cancelOrderData) {
            console.log("cancel order data", cancelOrderData);
            load_cart_totals(_self, _app);
          }
        });
      } else {
        // Carica i totali del carrello
        load_cart_totals(_self, _app);
      }

      document.arrive("#pre-order", function () {
        if (appCore.current_order_data.delivery_mode == 'pre-order') {
          $$('#pre-order').prop("checked", true).trigger('click');
          $$('#pre-order-time').val(appCore.current_order_data.pre_order_selection);
        }

        if (appCore.current_order_data.payment_mode == 'credit_card') {
          $$('#payment-method-credit-card').prop("checked", true).trigger('click');
        }

        if (appCore.current_order_data.payment_mode == 'cash') {
          $$('#payment-method-cash').prop("checked", true).trigger('click');
        }
      });
      document.arrive("#order-date", function () {
        if (appCore.current_order_data.orderDate != null) {
          orderDate = appCore.current_order_data.orderDate;
        }

        let _current_address = mapManager.Current_Address;
        $$('#shipping-address').html(_current_address);
        var yesterday = new Date().setDate(today.getDate() - 1);

        if (calendar != null) {
          calendar.destroy('order-date');
          calendar = null;
        }

        if (calendar == null) {
          console.log("testcalendario");
          document.getElementById('order-date').value = orderDate;
          var parts = orderDate.split('-');
          var visibleOrderDate = new Date(parts[2], parts[1] - 1, parts[0]);
          document.getElementById('order-date-visible').value = days[visibleOrderDate.getDay()] + " " + visibleOrderDate.getDate() + " " + months[visibleOrderDate.getMonth()] + " " + visibleOrderDate.getFullYear();
          calendar = f7app.calendar.create({
            inputEl: "#order-date",
            closeOnSelect: true,
            dateFormat: 'dd-mm-yyyy',
            timePicker: false,
            minDate: yesterday,
            on: {
              closed: function () {
                orderDate = document.getElementById('order-date').value;
                var parts = orderDate.split('-');
                var visibleOrderDate = new Date(parts[2], parts[1] - 1, parts[0]);
                document.getElementById('order-date-visible').value = days[visibleOrderDate.getDay()] + " " + visibleOrderDate.getDate() + " " + months[visibleOrderDate.getMonth()] + " " + visibleOrderDate.getFullYear();
                console.log("ricarica", orderDate);
                appCore.current_order_data.orderDate = orderDate;
                load_cart_totals(_self, _app);
                console.log("self2", _self);
              }
            }
          });
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: '925c9e4dc1',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding" style=background:#eeb42c;><div class=left><a class=back><img src=./static/images/tasto_back.png style="width:40px; margin:10px;"></a></div><div class=title style="text-align:center; width:80%; margin:0 auto;">';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<p style="color:black; font-size:20; font-wight:bold;">';
          r += c(ctx_2.data.restaurant_data.name, ctx_2);
          r += '</p>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=right><a href=/help/ ><img src=./static/images/tasto_aiuto.png style="width:40px; margin:10px;"></a></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="card skeleton-text skeleton-effect-wave"><div class=card-header>Card Header</div><div class="card-content card-content-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit.</div><div class=card-footer>Card Footer</div></div><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Info ristorante --><!-- Prodotti carrello --><div class=card><div class=card-header style="font-weight: bold;">Prodotti nel carrello</div><div class=card-content><div class="list media-list"><ul>';
          r += Template7Helpers.js_if.call(ctx_2, "typeof this.data.cart_items !== 'undefined'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.data.cart_items, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><a href="/cart-item-details/?id=';
                  r += c(ctx_4.id, ctx_4);
                  r += '" class="item-link item-content"><div class=item-inner><div class=item-title-row><div class=item-title style="font-style: italic; margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold;;">';
                  r += c(ctx_4.item_qty, ctx_4);
                  r += ' x ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-after>';
                  r += c(ctx_4.item_total, ctx_4);
                  r += '&euro;</div></div><div class=item-subtitle style="margin-left: 10px; font-style: italic; height: auto; white-space: pre-wrap; color: #b11733;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.ingredients_description, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-text></div></div></a></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div><!-- Note ordine --><div class=card><div class=card-header style="font-weight: bold;"><span>Note</span></div><div class="card-content card-content-padding"><div class=item-input><textarea id=instructions class=resizable placeholder="Scrivi qui il tuo messaggio..." style="border: 1px solid #e5e5e5; width: 100%; min-height: 80px;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.order_data.instructions, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</textarea></div><p>Se hai un\'allergia o un\'intolleranza alimentare (o se una persona per la quale stai effettuando un ordine ne ha) <b>comunicalo subito al ristorante: chiama <a href="tel:';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.restaurant_data.phone, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" class=external style="color:black ; text-decoration: underline;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.restaurant_data.phone, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a></b></p></div></div><!-- Indirizzo di consegna --><div class=card><div class=card-header style="font-weight: bold;">Indirizzo di consegna</div><div class="card-content card-content-padding"><div class="list inset"><ul><li><div class=item-content><div class=item-inner><div id=shipping-address class=item-title>';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.order_data.shipping_address, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li></ul></div></div></div><!-- Giorno consegna --><div class=card><div class=card-header style="font-weight: bold;">Giorno di consegna</div><div class="card-content card-content-padding"><div class="list inset"><ul><li><div class="item-content item-input"><div class=item-inner><div class=item-input-wrap><input type=text readonly=readonly id=order-date-visible style=z-index:1> <input type=text placeholder="Seleziona un giorno" readonly=readonly id=order-date style="color:rgba(255,255,255,0); margin-top:-40px; z-index:30; font-size:50px;"></div></div></div></li></ul></div></div></div><!-- Fascia oraria --><div class=card><div class=card-header style="font-weight: bold;">Fascia oraria di consegna</div><div class="card-content card-content-padding"><div class=list><ul>';
          r += Template7Helpers.js_if.call(ctx_2, " this.data.cart_totals_data.open == 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><label class="item-radio item-radio-icon-start item-content"><input id=fast-way type=radio name=shipping-time-limit value=fast-way checked=checked> <i class="icon icon-radio"></i><div class=item-inner><div class=item-title>Ordina il prima possibile</div></div></label></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, " this.data.cart_totals_data.time_slots.length != 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><label class="item-radio item-radio-icon-start item-content"><input id=pre-order type=radio name=shipping-time-limit value=pre-order> <i class="icon icon-radio"></i><div class=item-inner><div class=item-title>Seleziona orario</div><div class=item-after><div class="input input-dropdown" id=pre-order-time-input style="display: none;"><select id=pre-order-time>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.data.cart_totals_data.time_slots, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<option value=';
                  r += c(ctx_4.time_slot_start, ctx_4);
                  r += '-';
                  r += c(ctx_4.time_slot_end, ctx_4);
                  r += '>';
                  r += c(ctx_4.time_slot_start, ctx_4);
                  r += '-';
                  r += c(ctx_4.time_slot_end, ctx_4);
                  r += '</option>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</select></div></div></div></label></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, " this.data.cart_totals_data.time_slots.length == 0 && this.data.cart_totals_data.open == 0 ", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li id=closed><label class=item-content><div class=item-inner>Il ristorante non consegna nella data selezionata, ti preghiamo di modificare il giorno di consegna</div></label></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div><!-- Metodo di pagamento --><div class=card><div class=card-header style="font-weight: bold;">Metodo di pagamento</div><div class="card-content card-content-padding"><div class=list><ul>';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.restaurant_data.cash_payment_enabled == 1 ", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><label class="item-radio item-radio-icon-start item-content"><input id=payment-method-cash type=radio name=payment-method value=payment-method-cash checked=checked> <i class="icon icon-radio"></i><div class=item-inner><div class=item-title>Contanti alla consegna</div></div></label></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.restaurant_data.online_payment_enabled == 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><label class="item-radio item-radio-icon-start item-content"><input id=payment-method-credit-card type=radio name=payment-method value=payment-method-cash checked=checked> <i class="icon icon-radio"></i><div class=item-inner><div class=item-title>Carta di credito</div></div></label></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div><!-- Totali --><div class=card style="border:1px solid var(--f7-theme-color)"><div class=card-header style="font-weight: bold;">TOTALI</div><div class="card-content card-content-padding"><div class=list><ul><li><div class=item-content><div class=item-inner><div class=item-title>Totale prodotti:</div><div class=item-after><span id=items-total data-cart-items-total>';
          r += c(ctx_2.data.cart_totals_data.items_total, ctx_2);
          r += '</span>&euro;</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Spese di consegna:</div><div class=item-after><span id=shipping-cost data-cart-shipping-cost>';
          r += c(ctx_2.data.cart_totals_data.shipping_cost, ctx_2);
          r += '</span>&euro;</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title style="color:var(--f7-theme-color); font-size:21px;">TOTALE ORDINE:</div><div class=item-after style="color:var(--f7-theme-color); font-size:21px;"><span id=cart-total data-cart-total>';
          r += c(ctx_2.data.cart_totals_data.cart_total, ctx_2);
          r += ' </span>&euro;</div></div></div></li></ul></div></div><div class=card-footer></div></div><!-- Invio ordine --><div class=card style="margin-bottom: calc(20px + env(safe-area-inset-bottom));"><div class="card-content card-content-padding"><button id=send-order class="col button button-small button-fill" style="min-height: 50px;">Invia ordine</button></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  style: `
.card-header {
	color:var(--f7-theme-color);
}
`,
  styleScoped: false
};

function load_cart_totals(self, app) {
  console.log("Load cart", orderDate);
  var _request_params = {
    'restaurant_id': appCore.restaurant_id,
    'address_id': mapManager.Current_Address_Id,
    'order_date': appCore.current_order_data.orderDate,
    'session_token': appCore.session_token
  };
  console.log('params', _request_params);
  app.request({
    url: api_url + 'cart/calculate_cart_totals.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (cartTotalsData) {
      console.log("cart totals", cartTotalsData);
      orderDate = today.toLocaleDateString("it-IT");
      orderDate = appUtilities.replaceAll(orderDate, "/", "-");

      if (cartTotalsData.data.open == 0) {
        document.arrive("#pre-order", function () {
          console.log("orario");
          $$('#pre-order').prop("checked", true).trigger('click');
          $$('#pre-order-time').val(appCore.current_order_data.pre_order_selection);
          console.log("orario2");
        });
      } else {
        console.log("prima");
        $$('#fast-way').prop("checked", true).trigger('click');
      } //Controlla che il ristorante sia aperto


      if (cartTotalsData.data.open == "0" && cartTotalsData.data.time_slots.length == 0) {
        app.dialog.alert('Questo ristorante non è disponibile nella data selezionata, ti preghiamo di modificare il giorno di consegna. Puoi visualizzare i giorni di chiusura e gli orari di consegna nella pagina del menù del ristorante, Grazie!', "Attenzione");
      } // Carica i prodotti nel carrello


      cartManager.load_cart_data(false, function (userCartData) {
        self.$setState({
          data: {
            restaurant_data: appCore.current_restaurant_data,
            order_data: appCore.current_order_data,
            cart_totals_data: cartTotalsData.data,
            cart_items: userCartData.data
          }
        });
        console.log("selfdopo", self);
      });
    },
    error: function () {
      app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
    }
  });
}
    