
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      var _request_params = {
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'help_data.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (helpData) {
          console.log("help data", helpData);

          _self.$setState({
            data: helpData.data
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: 'ec4acb056d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class="navbar no-shadow" style=height:80px;><div class=navbar-bg></div><div class="navbar-inner sliding" style=background:white;><div class=left><a class=back><img src=./static/images/tasto_back.png style="width:40px; margin:10px;"></a></div><div class=title style="width:80%; margin 0 auto; text-align:center; color:black;"><img src=./static/images/tasto_aiuto.png style=width:40px;><p style=margin:0;>Aiuto</p></div><div class=right style="width:40px; margin:10px;"></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><div class=card-header></div><div class="card-content card-content-padding"><div class=list style="min-width: 100%; text-align: center; font-size: 18px;"><ul><li style="margin: 10px;">Se hai bisogno di assistenza per un ordine contattaci al <a href="tel:+39';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.help_phone_compact, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" class=external style="color:black ; text-decoration: underline;">+39 ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.help_phone_extended, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a></li><li style="margin: 10px;">Se hai riscontrato problemi con l\'App/sito inviaci un\'email a <a href=mailto:info@jacknb.com class=external style="color:black ; text-decoration: underline;">info@jacknb.com</a></li></ul></div></div></div><div class=card><div class="card-content card-content-padding"><img src=./static/images/help.jpeg style="width: 100%;"></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    