
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      window.stripePayment = new StripePayment();
      document.arrive("#payment-form", function () {
        stripePayment.init();
      });
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;

      let _current_page_name = ViewHome.router.currentPageEl.getAttribute('data-name');

      console.log(ViewHome.router.currentPageEl); //alert(_current_page_name);

      console.log("route /stripe-payment/");
      f7app.toolbar.hide('.toolbar', true);
      var _request_params = {
        'session_token': appCore.session_token
      };
      f7app.request({
        url: api_url + 'customer_order_to_pay.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (orderToPayData) {
          console.log("order to pay data", orderToPayData);
          appCore.order_id = orderToPayData.data.order_id;
          appCore.restaurant_id = orderToPayData.data.temp_restaurant_id;

          _self.$setState({
            data: orderToPayData.data
          });
        },
        error: function () {
          f7app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageBeforeOut: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: '2fc0a2101d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=stripe-payment style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left></div><div class=title>Pagamento ordine</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><div class=card-content><img src=./static/images/powered-by-stripe.svg style="width: 30%; margin: 10px;"></div></div><div class=card><div class=card-header style="color: #B23633; font-weight: bold; margin-bottom: 5px;">Totale da pagare: ';
          r += c(ctx_2.data.cart_totals.cart_total, ctx_2);
          r += '&euro;</div><div class=card-content><div class=sr-root style="width: 90%; padding: 0;"><form id=payment-form class=sr-payment-form style="width: 100%;"><div class=sr-combo-inputs-row><div class="sr-input sr-card-element" id=card-element style="width: 90%;"></div></div><div class=sr-field-error id=card-errors role=alert></div><button id=submit><div class="spinner hidden" id=spinner></div><span id=button-text>Paga</span><span id=order-amount></span></button> <button id=stripe-cancel-order class="col button button-small button-fill" style="min-height: 40px; margin-top: 20px;">Torna al carrello</button></form><div class="sr-result hidden"><p>Pagamento completato<br></p><pre>\r\n\r\n              <code></code>\r\n\r\n            </pre></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    