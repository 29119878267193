
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      user_logged = appUtilities.user_is_logged();

      if (user_logged == false) {
        return;
      }

      console.log("route /current-orders/");
      var _request_params = {
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'customer_current_orders.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (currentOrdersData) {
          console.log("customer current orders data", currentOrdersData);

          let _success = parseFloat(currentOrdersData.success);

          if (_success == 1) {
            let _page = $$('.page[data-name="current-orders"]')[0].f7Page;
            let _self = _page.$pageEl[0].f7Component;

            _self.$setState({
              data: currentOrdersData.data
            });
          }
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    }
  },
  id: '502ebbb326',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=current-orders style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding" style=background:#eeb42c;><div class=left style="width:40px; margin:10px;"></div><div class=title style="text-align:center; width:80%; margin:0 auto;"><p style="color:black; font-size:20; font-wight:bold;">I TUOI ORDINI</p></div><div class=right><a href=/help/ ><img src=./static/images/tasto_aiuto.png style="width:40px; margin:10px;"></a></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<!-- Ristorante e ordine --><div class=card><div class=card-header style="background-color: #b11733; font-weight: bold; font-weight: bold; color: #fff; font-weight: bold; margin-bottom: 10px;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.resturant_name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' [';
              r += c(ctx_3.order_number, ctx_3);
              r += '] - ';
              r += c(ctx_3.order_date, ctx_3);
              r += '</div><!-- Stato dell\'ordine --> ';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 0", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img style="width:100%; background-position: top;" src=./static/images/stato_ordine_attesa.png> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img style="width:100%; background-position: top;" src=./static/images/stato_ordine_accettato.png> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 2 || this.order_status == 3", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <img style="width:100%; background-position: top;" src=./static/images/stato_ordine_consegna.png> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '<div class=card-content style="padding-bottom: 5px;"><!-- Stato --><div class="list inset"><ul>';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 0", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Ordine in attesa di accettazione da parte del ristorante --><li><div class=item-content><div class="item-inner row"><div class="item-title col-100 xsmall-100 large-70" style="white-space: pre-wrap;">È il ristorante a confermare il tuo ordine ed a indicarci l\'orario di ritiro. Se è in attesa di accettazione da piu di 10 minuti, consigliamo di contattare direttamente il ristorante al <a href="tel:';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.phonenumber, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" class="phone-number external">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.phonenumber, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</a></div></div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Ordine accettato dal rider --><li><div class=item-content><div class="item-inner row"><div class="item-title col-100 xsmall-100 large-70" style="white-space: pre-wrap;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.resturant_name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ha accettato il tuo ordine e lo preparerà alle ore ';
                  r += c(ctx_4.restaurant_time_limit, ctx_4);
                  r += '. Ti ricordiamo che sono i ristoranti ad indicare l\'ora di ritiro.</div>';
                  r += Template7Helpers.js_if.call(ctx_4, "this.driver_details !== null", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class="item-title col-100 xsmall-100 large-70" style="white-space: pre-wrap;">Se dovessi aver bisogno di contattare il driver puoi chiamarlo al numero <a href="tel:';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.driver_details.phone, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '" class="phone-number external">';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.driver_details.phone, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</a></div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.self_delivery == 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><div class=item-content><div class="item-inner row"><div class="item-title col-100 xsmall-100 large-70" style="white-space: pre-wrap;">Questo partner consegna con il proprio personale, per informazioni sul tuo ordine puoi contattarlo al <a href="tel:';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.phonenumber, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" class="phone-number external">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.phonenumber, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</a></div></div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.order_status == 2 || this.order_status == 3", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Ordine in attesa di accettazione da parte del ristorante --><li><div class=item-content><div class="item-inner row"><div class="item-title col-100 xsmall-100 large-70" style="white-space: pre-wrap;">Il nostro driver ha ritirato il tuo ordine e sarà da te a momenti! Potremmo chiamarti per indicazioni sull\'indirizzo, pertanto tieni con te il cellulare. Se dovessi aver bisogno di contattare il driver, puoi chiamarlo al numero: <a href="tel:';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.driver_details.phone, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '" class="phone-number external">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.driver_details.phone, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</a></div></div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div><!-- Prodotti ordine --><div class=card><!-- Card header --><div class=card-header style="font-weight: bold;">Prodotti nel carrello</div><!-- Card body --><div class="card-content card-content-padding"><div class="list media-list"><ul>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.items, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-style: italic; margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold;;">';
                  r += c(ctx_4.item_qty, ctx_4);
                  r += ' x ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-after>';
                  r += c(ctx_4.item_total, ctx_4);
                  r += '&euro;</div></div><div class=item-subtitle style="margin-left: 10px; font-style: italic; height: auto; white-space: pre-wrap; color: #b11733;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.ingredients_description, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-text></div></div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div></div><!-- Totali --><div class=card style="border:1px solid var(--f7-theme-color)"><!-- Card body --><div class="card-content card-content-padding"><div class="list inset"><ul><li><div class=item-content><div class=item-inner><div class=item-title>Totale prodotti:</div><div class=item-after>';
              r += Template7Helpers.js.call(ctx_3, "appCore.financial(this.item_total)", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' &euro;</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Totale spedizione:</div><div class=item-after>';
              r += Template7Helpers.js.call(ctx_3, "appCore.financial(this.shipping_cost)", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' &euro;</div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title style="color:var(--f7-theme-color); font-size:21px;">TOTALE ORDINE:</div><div class=item-after style="color:var(--f7-theme-color); font-size:21px;">';
              r += Template7Helpers.js.call(ctx_3, "appCore.financial(this.grand_total)", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' &euro;</div></div></div></li></ul></div></div></div><!-- Metodo di pagamento --><div class="card no-border no-shadow"><div class=card-header style="font-weight: bold; color:black">Pagamento ';
              r += Template7Helpers.js_if.call(ctx_3, "this.payment_type_code == 'credit_card'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' con carta di credito <img src=./static/images/credit_card_icon.png style="width: 30px; margin-left: 2px; vertical-align: text-bottom;"> ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' in contanti alla consegna <img src=./static/images/money.png style="width: 32px; vertical-align: text-bottom;"> ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div><!-- Giorno e orario di consegna richiesto --><div class="card no-shadow"><div class=card-header style="font-weight: bold;color:black;">Consegna richiesta il ';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.extended_order_date).format('DD/MM/YYYY')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status == 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' per la fascia ';
                  r += c(ctx_4.time_limit, ctx_4);
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status != 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' il prima possibile ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div><!-- Indirizzo di consegna --><div class="card no-shadow"><div class=card-header style="font-weight: bold; color:black;">Indirizzo di consegna ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.delivery_address, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div><!-- Note ordine --> ';
              r += Template7Helpers.js_if.call(ctx_3, "this.instruction !== '' ", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card no-shadow"><!-- Card header --><div class=card-header style="font-weight: bold; color: black;">Note: ';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.instruction, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  style: `
.card-header {
	color:var(--f7-theme-color);
}
.phone-number{
  text-decoration: underline;
  font-weight:bold;
  z-index:2000;
}
`,
  styleScoped: false
};
    