import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

function StripePayment()
{
  if (!(this instanceof StripePayment)) 
  {
    return new StripePayment(stripePayment);
  }
  
  var self = this;
  var stripe;

  var orderData = 
  {
  };

  self.init = function()
  {
    // Recupera la chiave pubblica di Stripe

    fetch(api_url + "customer_stripe_keys.php",
    {
      method: "POST",
      headers: 
      {
        // NB: SEMBRA CHE QUESTO HEADER ATTIVO FA CASINO E MANDA IN FAIL LA RISPOSTA!!!!

        //"Content-Type": "application/json" 
      },
      body: JSON.stringify(
      {
        session_token : appCore.session_token
      })
    })
    .then(function(result) 
    {
      return result.json();
    })
    .then(function(data) 
    {
      return setupElements(data);
    })
    .then(function({ stripe, card, clientSecret }) 
    {
      var form = document.getElementById("payment-form");

      form.addEventListener("submit", function(event) 
      {
        event.preventDefault();

        $$('#stripe-cancel-order').css('display', 'none');

        pay(stripe, card, clientSecret);
      });
    });
  };

  var setupElements = function(data) 
  {
    stripe = Stripe(data.publishableKey, { stripeAccount: data.connectedAccountId});

    /* ------- Set up Stripe Elements to use in checkout form ------- */
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    var card = elements.create("card", { style: style });

    card.mount("#card-element");

    return {
      stripe: stripe,
      card: card,
      clientSecret: data.clientSecret
    };
  };

  var handleAction = function(clientSecret) 
  {
    stripe.handleCardAction(clientSecret).then(function(data) 
    {
      if (data.error) 
      {
        showError("La carta non è stata autenticata, si prega di riprovare");

        $$('#stripe-cancel-order').css('display', 'block');
      } 
      else if (data.paymentIntent.status === "requires_confirmation") 
      {
        fetch(api_url + "customer_pay_order.php", 
        {
          method: "POST",
          headers: 
          {
              // NB: SEMBRA CHE QUESTO HEADER ATTIVO FA CASINO E MANDA IN FAIL LA RISPOSTA!!!!

              //"Content-Type": "application/json" 
          },
          body: JSON.stringify(
          {
            paymentIntentId : data.paymentIntent.id,
            session_token : appCore.session_token,
            order_id : appCore.order_id,
            restaurant_id : appCore.restaurant_id
          })
        })
        .then(function(result) 
        {
          return result.json();
        })
        .then(function(json) 
        {
          if (json.error) 
          {
            showError(json.error);

            $$('#stripe-cancel-order').css('display', 'block');
          } 
          else 
          {
            orderComplete(clientSecret);
          }
        });
      }
    });
  };

  /*
  * Collect card details and pays for the order
  */
  var pay = function(stripe, card) 
  {
    changeLoadingState(true);

    stripe
      .createPaymentMethod("card", card)
      .then(function(result) 
      {
        if (result.error) 
        {
          showError(result.error.message);

          $$('#stripe-cancel-order').css('display', 'block');
        } 
        else 
        {
          orderData.payment_method_id = result.paymentMethod.id;
          orderData.order_id = appCore.order_id;
          orderData.restaurant_id = appCore.restaurant_id;
          orderData.session_token = appCore.session_token;

          return fetch(api_url + "customer_pay_order.php", 
          {
            method: "POST",
            headers: 
            {
              // NB: SEMBRA CHE QUESTO HEADER ATTIVO FA CASINO E MANDA IN FAIL LA RISPOSTA!!!!

              //"Content-Type": "application/json" 
            },
            body: JSON.stringify(orderData)
          });
        }
      })
      .then(function(result) 
      {
        console.log("payment result", result);

        return result.json();
      })
      .then(function(paymentData) 
      {
        console.log("payment data", paymentData);

        if (paymentData.requiresAction) 
        {
          // Request authentication

          handleAction(paymentData.clientSecret);
        } 
        else if (paymentData.error) 
        {
          // Errore

          showError(paymentData.error);

          $$('#stripe-cancel-order').css('display', 'block');
        } 
        else 
        {
          // Pagamento completato

          orderComplete(paymentData);
        }
      });
  };

  /* ------- Post-payment helpers ------- */

  /* Shows a success / error message when the payment is complete */
  var orderComplete = function(paymentData) 
  {
    if(typeof paymentData.success !== 'undefined')
    {
      // Impossibile effettuare la transazione Stripe

      let _msg = paymentData.msg;
  
      f7app.dialog.alert(_msg);
  
      changeLoadingState(false);
    }
    else
    {
      // Transazione Stripe completata

      fbq('track', 'Purchase', {currency: "EUR", value: 1});

      gtag_report_conversion();
      
      f7app.dialog.alert("Pagamento completato con successo!", "Ordine inviato", function()
      {
        window.location.href = app_url;
      });
    }
  };

  var showError = function(errorMsgText) 
  {
    changeLoadingState(false);

    var errorMsg = document.querySelector(".sr-field-error");

    errorMsg.textContent = errorMsgText;

    setTimeout(function() 
    {
      errorMsg.textContent = "";
    }, 
    4000);
  };

  // Show a spinner on payment submission
  var changeLoadingState = function(isLoading) 
  {
    if (isLoading) 
    {
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } 
    else 
    {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };
}

export default StripePayment;