import $$ from 'dom7';
import Template7 from 'template7';

function CartManager()
{
    if (!(this instanceof CartManager)) 
    {
        return new CartManager(cartManager);
    }
    
    var self = this;

    $$(document).on('click', '#back-to-cart', function (e) 
    {
        ViewHome.router.back();
    });

    // --- Cart items ---

    $$(document).on('click', '[data-cart-item-action]', function (e) 
    {
        let _this = $$(this);

        let _action = _this.attr('data-cart-item-action');
        
        let _cart_item_row_id = parseInt($$('#cart-item-row-id').val());
        
        switch(_action)
        {
            case 'add-cart-item':

                // Aggiunge l'articolo al carrello

                let _item_id = parseInt(_this.attr('data-cart-item-id'));

                self.add_cart_item(_item_id);

                fbq('track', 'AddToCart');
                
                break;

            case 'increase-cart-item-qty':
            case 'decrease-cart-item-qty':

                let _cart_item_qty = parseInt($$('#cart-item-qty').html());

                self.set_cart_item(_cart_item_row_id, _cart_item_qty);

                break;
            
            case 'delete-cart-item':

                self.delete_cart_item(_cart_item_row_id);

                break;
        }
    });

    self.add_cart_item = function(itemId)
    {
        let _request_params = 
        {
            'item_id' : itemId,
            'restaurant_id' : appCore.restaurant_id,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/add_cart_item.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (addCartItemData) 
            {
                console.log("add cart item data", addCartItemData);

                let _success = parseFloat(addCartItemData.success);

                if(_success == 1)
                {
                    let _cart_item = addCartItemData.data;
                    ViewHome.router.navigate("/cart-item-details/?id=" + _cart_item.id,
                    {
                        ignoreCache: true,
                        force: true
                    });
                }
                else
                {
                    f7app.dialog.alert(addCartItemData.msg);
                }
            },
            error: function () 
            {
                f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
            }
        });
    }

    self.set_cart_item = function(cartItemRowId, cartItemQty)
    {
        let _request_params = 
        {
            'cart_item_row_id' : cartItemRowId,
            'cart_item_qty' : cartItemQty,
            'restaurant_id' : appCore.restaurant_id,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/set_cart_item.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (cartItemDetailsData) 
            {
                console.log("set cart item", cartItemDetailsData);

                let _success = parseFloat(cartItemDetailsData.success);

                if(_success == 1)
                {
                    let _page = $$('.page[data-name="cart-item-details"]')[0].f7Page; 
                    let _self = _page.$pageEl[0].f7Component; 
                    
                    _self.$setState(
                    { 
                        data: cartItemDetailsData.data,
                        cartItemData: 
                        {
                            id : cartItemDetailsData.data.id
                        }
                    });
                }
                else
                {
                    f7app.dialog.alert(cartItemDetailsData.msg);
                }
            },
            error: function () 
            {
                f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
            }
        });
    }

    self.delete_cart_item = function(cartItemRowId)
    {
        let _request_params = 
        {
            'cart_item_row_id' : cartItemRowId,
            'restaurant_id' : appCore.restaurant_id,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/delete_cart_item.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (data) 
            {
                console.log("delete cart item", data);

                let _success = parseFloat(data.success);

                if(_success == 1)
                {
                    ViewHome.router.back();
                }
                else
                {
                    f7app.dialog.alert(data.msg);
                }
                },
            error: function () 
            {
                f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
            }
        });
    }

    // --- Cart ingredients ---

    $$(document).on('click', '[data-cart-ingredient-action]', function (e) 
    {
        let _this = $$(this);

        let _action = _this.attr('data-cart-ingredient-action');
        
        let _cart_item_row_id = parseInt($$('#cart-item-row-id').val());
        let _cart_ingredient_id = parseInt(_this.attr('data-cart-ingredient-id'));

        switch(_action)
        {
            case 'add-cart-ingredient':

                // Aggiunge la variante all'articolo

                self.add_cart_ingredient(_cart_item_row_id, _cart_ingredient_id);

                break;

            case 'remove-cart-ingredient':

                // Diminuisce la quantità della variante di 1

                self.remove_cart_ingredient(_cart_item_row_id, _cart_ingredient_id);

                // Ferma la propagazione del click altrimenti si potrebbe
                // riscatenare il click del link per aggiungere la variante
                
                e.stopPropagation();

                break;
        }
    }); 

    self.add_cart_ingredient = function(cartItemRowId, ingredientId)
    {
        let _request_params = 
        {
            'cart_item_row_id' : cartItemRowId,
            'ingredient_id' : ingredientId,
            'restaurant_id' : appCore.restaurant_id,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/add_cart_ingredient.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (cartItemDetailsData) 
            {
                console.log("add cart ingredient", cartItemDetailsData);

                let _success = parseFloat(cartItemDetailsData.success);

                if(_success == 1)
                {
                    let _page = $$('.page[data-name="cart-item-details"]')[0].f7Page; 
                    let _self = _page.$pageEl[0].f7Component; 
                    
                    _self.$setState(
                    { 
                        data: cartItemDetailsData.data,
                        cartItemData: 
                        {
                            id : cartItemDetailsData.data.id
                        }
                    });
                }
                else
                {
                    f7app.dialog.alert(cartItemDetailsData.msg);
                }
            },
            error: function () 
            {
                f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
            }
        });
    }

    self.remove_cart_ingredient = function(cartItemRowId, ingredientId)
    {
        let _request_params = 
        {
            'cart_item_row_id' : cartItemRowId,
            'ingredient_id' : ingredientId,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/remove_cart_ingredient.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (cartItemDetailsData) 
            {
                console.log("remove cart ingredient", cartItemDetailsData);

                let _success = parseFloat(cartItemDetailsData.success);

                if(_success == 1)
                {
                    let _page = $$('.page[data-name="cart-item-details"]')[0].f7Page; 
                    let _self = _page.$pageEl[0].f7Component; 
                    
                    _self.$setState(
                    { 
                        data: cartItemDetailsData.data,
                        cartItemData: 
                        {
                            id : cartItemDetailsData.data.id
                        }
                    });
                }
                else
                {
                    f7app.dialog.alert(cartItemDetailsData.msg);
                }
            },
            error: function () 
            {
            f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
            }
        });
    }

    // --- General methods ---

    self.load_cart_data = function(resetCurrentCart, callback)
    {
		
        var _request_params = 
        {
            'restaurant_id' : appCore.restaurant_id,
            'reset_current_cart' : resetCurrentCart,
            'session_token' : appCore.session_token
        };

        f7app.request(
        {
            url: api_url + 'cart/show_user_cart.php',
            dataType: 'json',
            data: _request_params,
            method: 'POST',
            cache: false,
            crossDomain: true,
            success: function (userCartData) 
            {
				
                if(typeof callback !== 'undefined')
                {
                    callback(userCartData);
                }
            },
            error: function () 
            {
                f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
            }
        });
    }
}

export default CartManager;
