
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      f7app.toolbar.show('.toolbar', true); //scelgo se mostra i link per scaricare l'app

      document.arrive(".web-footer-link2", function () {
        if (f7app.device.cordova) {
          $$('.web-footer-link2').remove();
        }
      }); // Carica i dati del ristorante selezionato

      var _restaurant_id = _route.query.id;
      var _reset_current_cart = false;

      if (appCore.restaurant_id != _restaurant_id) {
        // Selezionato ristorante diverso, azzero il carrello
        _reset_current_cart = true;
      }

      appCore.restaurant_id = _restaurant_id;
      var _request_params = {
        'restaurant_id': appCore.restaurant_id,
        'reset_current_cart': _reset_current_cart == true ? 1 : 0,
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'customer_restaurant_items.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (restaurantItemsData) {
          console.log("restaurant items data", restaurantItemsData);
          let _total_items_qty = restaurantItemsData.data.total_items_qty;

          let _goto_checkout_exists = $$('#goto-checkout').length > 0;

          if (_total_items_qty > 0) {
            if (_goto_checkout_exists == true) {
              show_goto_checkout();
            } else {
              document.arrive("#goto-checkout", function () {
                show_goto_checkout();
              });
            }
          } else {
            if (_goto_checkout_exists == true) {
              hide_goto_checkout();
            } else {
              document.arrive("#goto-checkout", function () {
                hide_goto_checkout();
              });
            }
          }

          load_restaurant_data(function () {
            _self.$setState({
              data: restaurantItemsData.data,
              restaurantData: appCore.current_restaurant_data
            });
          });
          $$('.cerca').once('click', function (e) {
            var searchbar = f7app.searchbar.create({
              el: '.searchbar',
              searchContainer: '.listamia',
              searchIn: '.restaurant-item-name',
              hideGroups: true,
              hideDividers: true,
              searchGroup: '.list-group , .box-item-search',
              searchGroupTitle: '.list-group-title , .box-item-search',
              once: {
                search(sb, query, previousQuery) {
                  console.log(query, previousQuery);
                }

              }
            });
            f7app.searchbar.toggle('.searchbar'); //$$('#standard-navbar').css('display', 'none');
            //$$('#search-navbar').css('display', 'block');
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: '3168fba921',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><div class="navbar navbar-transparent" id=standard-navbar><div class=navbar-bg style="background:#eeb42c; margin:0px"></div><div class=navbar-inner><div class=left><a class="link back"><img src=./static/images/tasto_back.png style="width:40px; margin:10px;"></a></div><div class=title></div><div class=right style=margin-right:30px;><!-- Link per condividere  --> <a class="link icon-only"><img src=./static/images/condividi.png style="width:40px; margin-right:10px;" class=condividi> </a><!-- Link to enable searchbar --> <a class="link icon-only searchbar-enable" data-searchbar=.searchbar><img src=./static/images/tasto_cerca.png style="width:40px; margin-right:10px;" class=cerca></a></div><!-- Searchbar is a direct child of "navbar-inner" --><form class="searchbar searchbar-expandable"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder=Search> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class=searchbar-disable-button>Cancel</span></div></form><!--<div class="left">\r\n            <a class="link back">\r\n              <img src="./static/images/tasto_back.png" style=" width:40px; margin:10px;"/>\r\n            </a>\r\n          </div>\r\n\r\n          <div class="title"></div>\r\n\r\n          <div class="right">\r\n\r\n            <a class="link" id="search_icon">\r\n              <img src="./static/images/tasto_cerca.png" style=" width:40px; margin:10px;"/>\r\n            </a>\r\n\r\n          </div> --></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="card skeleton-text skeleton-effect-wave"><div class=card-header>Card Header</div><div class="card-content card-content-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit.</div><div class=card-footer>Card Footer</div></div><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Logo e dati vari ristorante --><div class=searchbar-backdrop></div><div id=restaurant_menu_cover class=block style="margin-top: -55px; background-image: url(';
          r += Template7Helpers.js.call(ctx_2, 'restaurants_cover_img_url', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += c(ctx_2.restaurantData.cover_image, ctx_2);
          r += ')"></div><div class="card ombra" style="margin-top:-30px; z-index:2000"><div class="card-content card-content-padding"><div class="list media-list"><ul><li><div class=item-content><div class="item-inner no-chevron"><div class=item-title-row><div class=item-title style="text-align: center; width: 100%; font-size: 20px; font-weight: bold;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.restaurantData.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class=item-subtitle style="text-align:center; height: auto; white-space: pre-wrap; margin-top: 10px;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.restaurantData.partner_info, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-subtitle style="text-align:center; height: auto; white-space: pre-wrap; margin-top: 5px;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.restaurantData.closing_day, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-subtitle style="text-align:center; height: auto; white-space: pre-wrap; margin-top: 5px;">Consegna: ';
          r += c(ctx_2.restaurantData.shipping_cost, ctx_2);
          r += ' &euro; &#8226; Ordine minimo: ';
          r += c(ctx_2.restaurantData.min_order_amount, ctx_2);
          r += ' &euro;</div><div class=item-subtitle style="text-align:center; height: auto; white-space: pre-wrap; margin-top: 5px;"><img src=./static/images/marker_icon.svg style="width: 13px; vertical-align: text-bottom;"> ';
          r += c(ctx_2.restaurantData.distance, ctx_2);
          r += ' Km &#8226; Pagamento: ';
          r += Template7Helpers.js_if.call(ctx_2, "this.restaurantData.cash_payment_enabled == 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=./static/images/money.png style="width: 22px; vertical-align: text-bottom;"> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.restaurantData.online_payment_enabled == 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=./static/images/credit_card_icon.png style="width: 20px; margin-left: 2px; vertical-align: text-bottom;"> ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=web-footer-link2 style="margin-top: 15px;"><a href="https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it"><img height=90% src=./static/images/androidmarketIcona.png onclick=\'location.assign("https://play.google.com/store/apps/details?id=com.jackbcustomer&hl=it")\'></a><a href=https://apps.apple.com/app/id1591974126><img height=90% src=./static/images/appleStoreIcona.png onclick=\'location.assign("https://apps.apple.com/app/id1591974126")\'></a></div></div></div></li></ul></div></div></div><!-- Articoli menu\' --><div class="card searchbar-found"><div class="card-content card-content-padding"><div class="list accordion-list listamia"><!-- Categorie --><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.categories, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=accordion-item><!-- Header categoria --> <a href="" class="item-link item-content"><div class=item-inner><div class="item-title category" style="font-weight: bold;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></a><!-- Body categoria --><div class=accordion-item-content><div class="list media-list"><div class=list-group><ul>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.sub_categories, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li class="list-group-title subcategory" style="font-weight: bold; color: #B23633; font-weight: bold;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</li>';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.items, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=box-item-search style="padding: 5px; margin: 20px 10px 20px 10px; border-radius: 10px; border: 1px solid #555;"><!-- Dettagli articolo --><li data-cart-item-id=';
                      r += c(ctx_5.item_id, ctx_5);
                      r += '><a href=# class="item-link item-content no-padding" data-cart-item-action=add-cart-item data-cart-item-id=';
                      r += c(ctx_5.item_id, ctx_5);
                      r += '><!--\r\n                                  <div class="item-media">\r\n\r\n                                    <img src="https://cdn.framework7.io/placeholder/people-160x160-2.jpg" width="80" />\r\n\r\n                                  </div>\r\n                                  --><div class="item-inner no-chevron no-padding"><div class=item-title-row><!-- Blocco dati prodotto --><div class="row no-gap" style="min-width: 100%;">';
                      r += Template7Helpers.js_if.call(ctx_5, "this.image.includes('default_blank_item.png') == false && this.image.trim() != ''", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=col-70 style="padding-left: 5px;"><div class=item-title style="font-size: 14px;"><span class=restaurant-item-name data-item-id=';
                          r += c(ctx_6.item_id, ctx_6);
                          r += ' data-item-name style="margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold;">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.name, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</span> <span data-item-id=';
                          r += c(ctx_6.item_id, ctx_6);
                          r += ' data-item-price style="font-weight: bold;">- ';
                          r += c(ctx_6.price, ctx_6);
                          r += '&euro;</span></div><div class=item-subtitle style="height: auto; white-space: pre-wrap; font-size: 14px; text-overflow:ellipsis; overflow:hidden;">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.description, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</div></div><div class=col-30 style="padding: 0px;"><img src="';
                          r += Template7Helpers.js.call(ctx_6, 'img_url', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += c(ctx_6.image, ctx_6);
                          r += '" style="float: right; margin-right: 5px; width: 80px; height: 80px; border-radius: 10px; object-fit: cover;"></div>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=col-100 style="padding-left: 5px;"><div class=item-title style="font-size: 14px;"><span class=restaurant-item-name data-item-id=';
                          r += c(ctx_6.item_id, ctx_6);
                          r += ' data-item-name style="margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold;">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.name, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</span> <span data-item-id=';
                          r += c(ctx_6.item_id, ctx_6);
                          r += ' data-item-price style="font-weight: bold;">- ';
                          r += c(ctx_6.price, ctx_6);
                          r += '&euro;</span></div><div class=item-subtitle style="height: auto; white-space: pre-wrap; font-size: 14px; text-overflow:ellipsis; overflow:hidden;">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.description, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</div></div>';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div></div><div class=item-text><!-- VUOTO --></div></div></a></li><!-- Articoli carrello --> ';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.item_cart_data, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<li class=cart-item data-cart-item-row-id=';
                          r += c(ctx_6.id, ctx_6);
                          r += ' style="background-color: #fff; margin-left: 10px;"><a href="/cart-item-details/?id=';
                          r += c(ctx_6.id, ctx_6);
                          r += '" class="item-link item-content"><!--\r\n                              \r\n                                  <div class="item-media">\r\n                              \r\n                                    <span class="badge" style="background-color: #b11733; width: 20px; height: 20px;">1</span>\r\n                              \r\n                                  </div>\r\n                                \r\n                                  --><div class=item-inner><div class=item-title-row><!-- Blocco dati prodotto --><div class=item-title style="margin-bottom: 10px"><span style="font-style: italic; margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold; color: #b11733;">';
                          r += c(ctx_6.item_qty, ctx_6);
                          r += ' x ';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.name, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</span></div><!-- Blocco dati carrello prodotto --><div class=item-after>';
                          r += c(ctx_6.item_total, ctx_6);
                          r += '&euro;</div></div><div class=item-subtitle style="font-style: italic; color: #b11733; height: auto; white-space: pre-wrap;">';
                          r += Template7Helpers.escape.call(ctx_6, ctx_6.ingredients_description, {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</div><div class=item-text><!-- VUOTO --></div></div></a></li>';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div><!-- Sezione vuota per pulsante "vai al carrello" --><div id=white-space-checkout class=item-content style="height: 50px;"><div class=item-inner><div class=item-title style="font-weight: bold;"></div></div></div><!-- Vai al carrello --> <a id=goto-checkout href=# class="button button-raised button-round button-fill button-large" style="display: none; margin-top: 20px; position: fixed; bottom: 0px; z-index: 9999; left: 50%; transform: translateX(-50%); width: 400px; max-width: 90%;">Vai al carrello</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  style: `

.accordion-item .item-title /*Trick per risolvere problema flickering accordion*/
{
  z-index: 10;
  position: relative;
  -webkit-backface-visibility: hidden;
}

.image-restaurant
{
  height:100px;
  display:block;
  margin:-50px auto;
}

.category
{
  font-size: 16px !important;
}

.subcategory
{
  font-size: 14px !important;
}

.ombra{
  border-radius:25px;
	box-shadow: 7px 7px 7px #777777;
	margin:30px;
}
`,
  styleScoped: false
};

function load_restaurant_data(callback) {
  var _request_params = {
    'latitude': mapManager.Current_Location.lat,
    'longitude': mapManager.Current_Location.lng,
    'restaurant_id': appCore.restaurant_id,
    'session_token': appCore.session_token
  };
  f7app.request({
    url: api_url + 'customer_restaurant_details.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) {
      console.log("restaurant details data", data);
      appCore.current_restaurant_data.id = data.data.restaurant_id;
      appCore.current_restaurant_data.name = data.data.resturant_name;
      appCore.current_restaurant_data.address = data.data.address;
      appCore.current_restaurant_data.image = data.data.image;
      appCore.current_restaurant_data.cover_image = data.data.cover_image;
      appCore.current_restaurant_data.latitude = data.data.Latitude;
      appCore.current_restaurant_data.longitude = data.data.Longitude;
      appCore.current_restaurant_data.phone = data.data.phonenumber;
      appCore.current_restaurant_data.opening_hours = data.data.opening_hours;
      appCore.current_restaurant_data.partner_info = data.data.partner_info;
      appCore.current_restaurant_data.closing_day = data.data.closing_day;
      appCore.current_restaurant_data.online_payment_enabled = data.data.online_payment_enabled;
      appCore.current_restaurant_data.cash_payment_enabled = data.data.cash_payment_enabled;
      appCore.current_restaurant_data.min_order_amount = data.data.min_order_amount;
      appCore.current_restaurant_data.shipping_cost = data.data.shipping_cost;
      appCore.current_restaurant_data.distance = data.data.distance;

      if (typeof callback !== 'undefined') {
        callback();
      }
    },
    error: function () {
      f7app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
    }
  });
}

function show_goto_checkout() {
  // Posiziona il tasto "vai al carrello"
  let _main_toolbar_height = $$('#main-toolbar').height();

  let _main_toolbar_height_css = 'calc(env(safe-area-inset-bottom) + ' + _main_toolbar_height + 'px)';

  $$('#goto-checkout').css('bottom', _main_toolbar_height_css); // Cambia l'altezza dello spazio vuoto dove ci andrà sopra il tasto checkout

  let _goto_checkout_height = $$('#goto-checkout').height();

  let _goto_checkout_height_css = 'calc(env(safe-area-inset-bottom) + ' + _goto_checkout_height + 'px)';

  $$('#white-space-checkout').css('height', _goto_checkout_height_css);
  $$('#goto-checkout').css('display', 'block');
}

function hide_goto_checkout() {
  $$('#goto-checkout').css('display', 'none');
}
    