import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import '../css/media-queries.css';
import '../css/stripe-payment.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';

// Import Moment.js
import * as moment from 'moment';
import 'moment/locale/it';

// Import main app component
import App from '../app.f7.html';

import AppCore from './app-core.js';
import AppUtilities from './app-utilities.js';
import MapManager from './map-manager.js';
import CartManager from './cart-manager.js'
import StripePayment from './stripe-payment.js';
import Arrive from './arrive-2.4.1.min.js';
import Geo from './geo.js';

window.app_type = 'customer';
window.app_version = '2.1.3.1';
window.debug_enabled = false;
window.device_type = '';

window.app_url = '';
window.push_state_root = '';
window.shared_restaurant = '';

window.webapp_url = 'https://user.jacknb.com/'
//window.webapp_url = 'https://dev.user.jacknb.com/'
//window.webapp_url ='https://dev.app.jacknb.com/'

window.api_url = 'https://api.jacknb.com/'; 
//window.api_url = 'https://dev.api.jacknb.com/'; 
//window.api_url = 'http://localhost/api_jacknb/';
//window.api_url = 'http://192.168.0.104/api_jacknb/';

//window.img_url = 'https://dev.api.jacknb.com/';
window.img_url = 'https://api.jacknb.com/';
//window.img_url = 'http://localhost/api_jacknb/';

window.restaurants_img_url = 'https://app.jacknb.com/';
window.items_img_url = 'https://dev.app.jacknb.com/';
window.restaurants_cover_img_url = 'https://api.jacknb.com/restaurant_images/covers/thumbs/';

window.StripePayment = StripePayment;
window.Arrive = Arrive;

window.general_error_message = "A causa della connessione a internet limitata le prestazioni dell'app potrebbero essere ridotte";

window.user_logged = false;

window.onesignalPlayerId = "";

console.log('App version: ' + app_version);

// Recupera i dati del dispositivo

device_type = get_device_type();

console.log("tipo " + device_type)

// Permette la registrazione del dispositivo per ricevere le notifiche

document.addEventListener('deviceready', OneSignalInit, false);

function OneSignalInit() 
{
    // NOTE: Update the setAppId value below with your OneSignal AppId.

    window.plugins.OneSignal.setAppId("2bd81de8-6aaf-4c8c-b4b0-760665ea7d69");

    window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) 
    {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));        
    });
    
    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) 
    {
      console.log("User accepted notifications: " + accepted);
    });
  
    //Recupero l'ID dell'utente a cui inoltrare le notifiche

    window.plugins.OneSignal.getDeviceState(function(stateChanges) 
    {
      console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
      console.log('PlayerID ' + JSON.stringify(stateChanges["userId"]));
      
      window.onesignalPlayerId = JSON.stringify(stateChanges["userId"]);
    });
}

if(debug_enabled == false)
{
  var _console = {};

  window.console = _console;

  console.log = function(){};
  console.info = function(){};
  console.warn = function(){};
  console.error = function(){};
}

new AppCore(function()
{
});

window.mapManager = new MapManager();
window.cartManager = new CartManager();
window.appUtilities = new AppUtilities();
window.moment = moment;

window.ViewMain = null;
window.ViewLogin = null;
window.ViewHome = null;
window.ViewProfile = null;

window.f7app = new Framework7(
{
  root: '#app', // App root element
  component: App, // App main component
  id: 'com.jacknb.user', // App bundle ID
  name: 'jacknb_user', // App name
  theme: 'auto', // Automatic theme detection
  cache: false,
  cacheDuration: 0,
  pushState: true,

  // App routes
  routes: routes,

  // Register service worker
  serviceWorker: Framework7.device.cordova ? {} : 
  {
    path: '/service-worker.js',
  },
  // Input settings
  input: 
  {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: 
  {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  // Lazy load
  lazy: 
  {
    threshold: 0,
    sequential: false,
  },
  on: 
  {
    init: function () 
    {
      var f7 = this;

      if (f7.device.cordova) 
      {
        // Init cordova APIs (see cordova-app.js)
        
        cordovaApp.init(f7);
      }

      ViewMain = f7app.views.create('.view-main',
      {
        url: 'index.html',
        pushState: true,
        pushStateRoot: document.location.pathname.split('index.html')[0],
        animate : false,
        on: 
        {
          init : function () 
          {
            var f7 = this;

            // Set push state root

            window.push_state_root = document.location.pathname.split('index.html')[0];
            window.shared_restaurant = window.location.href.split('=')[1];

            console.log('Push state root: ' + window.push_state_root);
            console.log('Ristorante: ' + window.shared_restaurant);
            console.log('test', window.location.href.split('=')[1]);

            // Set app url
            
            window.app_url = window.location.href;

            // Recupera i dati del dispositivo

            device_type = get_device_type();

			      console.log("tipo " + device_type)

            // Verifica se ci sono aggiornamenti disponibili

            get_current_app_version();

            // Prosegue col caricamento dell'applicazione

            on_app_load(f7);
          }
        }
      });
    },
    toolbarHide: function()
    {
    },
    toolbarShow: function()
    {
    }
  },
  methods: 
  {
    onBackKeyDown: function() 
    {
      var leftp = app.panel.left && app.panel.left.opened;
      var rightp = app.panel.right && app.panel.right.opened;

      if (leftp || rightp) 
      {
          f7app.panel.close();

          return false;
      }
      else if ($$('.modal-in').length > 0)
      {
          f7app.dialog.close();
          f7app.popup.close();

          return false;
      } 
      else if (f7app.views.main.router.url == '/home/') 
      {
        navigator.app.exitApp();
      }
      else 
      {
        let _current_page_name = ViewHome.router.currentPageEl.getAttribute('data-name');

        console.log('current page name' + _current_page_name);

        if(_current_page_name == "cart-item-details")
        {
          return;
        }

        ViewMain.router.back();
      }
    }
  }
});

// --- General APP functions ---

function on_app_load(f7)
{  
  user_logged = appUtilities.user_is_logged();

  let _guest_session_token_is_valid = appUtilities.guest_token_is_valid();

  if
  (
    user_logged == false 
    && _guest_session_token_is_valid == false
  )
  {
    // Richiede un token di sessione guest

    let _request_params = 
    {
      'user_type' : 'Customer',
      'device_type' : 'Web'
    };

    f7app.request(
    {
      url: api_url + 'customer_guest_access.php',
      dataType: 'json',
      data: _request_params,
      method: 'POST',
      cache: false,
      crossDomain: true,
      success: function (data) 
      {
        console.log('customer guest access', data);
  
        let _session_token = data.data.session_token;

        appCore.session_token = _session_token

        appUtilities.setParam('session-token', _session_token);

        send_app_and_device_data();
      },
      error: function() 
      {
        f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
      }
    });
  }
  else
  {
    appCore.session_token = appUtilities.getParam('session-token');

    // Verifica se il token di sessione è valido
  
    var _request_params = 
    {
      'token' : appCore.session_token
    };
  
    f7app.request(
    {
      url: api_url + 'verify_token.php',
      dataType: 'json',
      data: _request_params,
      method: 'POST',
      cache: false,
      crossDomain: true,
      success: function (data) 
      {
        console.log("verify token", data);
  
        let _success = parseFloat(data.success);

        if(_success == false)
        {
          // Effettua il logout
  
          log_out();
        }
        else
        {
          send_app_and_device_data();
  
          // Verifica se c'è un ordine in sospeso da pagare con carta di credito
  
          load_order_to_pay(function(thereIsOrderToPay)
          {
            appCore.thereIs_order_to_pay = thereIsOrderToPay;
  
            if(thereIsOrderToPay == false)
            {
              // Effettua il primo caricamento degli ordini in sospeso
  
              load_current_orders();
            }
          });
        }
      },
      error: function () 
      {
        f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
      }
    });
  }
 
}

// --- General DOM ---

$$(document).on('backbutton', function(e)
{
  //alert('back');

  let _current_page_name = ViewHome.router.currentPageEl.getAttribute('data-name');

  //alert("pagina corrente " + _current_page_name);

  if(_current_page_name == "stripe-payment")
  {
    e.preventDefault();
    e.stopPropagation();
    
    return false;
  }
});

$$(document).on('DOMNodeInserted', '.pac-container', function() 
{
  $$('.pac-item, .pac-item span').addClass('no-fastclick');
});

// --- General Pages ---

$$(document).on('page:beforein', function (e)
{
  //f7app.preloader.hide();
});

$$(document).on('page:afterin', function (e)
{
  //f7app.preloader.hide();
  
});

// --- Past orders ---

$$(document).on('click', '#left-slide', function (e) 
{
  past_orders_swiper.updateSlides()
  past_orders_swiper.slidePrev();								
});

$$(document).on('click', '#right-slide', function (e) 
{
  past_orders_swiper.updateSlides();
  past_orders_swiper.slideNext();				
});	

// --- Address ---

$$(document).on('click','#current_location', function (e) 
{
	setMap();

	mapManager.Get_Current_Location();			
});

$$(document).on('click', '#input-current-position-address', function (e) 
{
  setMap();			
});

$$(document).on('keypress', '#current-position-address', function (e) 
{
	$$('#confirm-current-position-address').css('display', 'none');				
});

function setMap()
{
  mapManager.Init_Current_Position_Map();
  mapManager.Init_AutoComplete();
}

function streetNumberNotSetAlert()
{
	f7app.dialog.alert('Indirizzo non completo, controlla che sia specificato anche il numero civico!', 'Attenzione', function(){});
}

$$(document).on('click', '[data-address-id]', function (e) 
{
	if (mapManager.Street_Number_Is_Set){
		ConifirmAddress()
	}else{
		f7app.dialog.create({
      title: 'Attenzione',
      text: 'Sembra che nell\'indirizzo specificato manchi il numero civico',
      buttons: [
        { text: 'Annulla'},
        { text: 'Continua senza il civico',  },
      ],
      onClick: function (dialog, index) {
        if(index === 1){
          confirmAddress()
        }
      },
      verticalButtons: true,
      }).open();
	}
});

function ConifirmAddress() {
  let _address_id = parseFloat($$(this).data('address-id'));
  let _address = $$('span[data-address-id="' + _address_id + '"][data-address]').html();

  mapManager.Get_LatLng_By_Address(_address, function (location) {
    mapManager.Current_Address_Id = _address_id;
    mapManager.Current_Address = _address;
    mapManager.Current_Location = location;

    confirm_current_location();
  });
}

$$(document).on('click', '#confirm-current-position-address', function (e) 
{
	if (mapManager.Street_Number_Is_Set){
	  confirmCurrentPositionAddress() 
	}else{
		/*f7app.dialog.confirm('Sembra che nell\'indirizzo specificato manchi il numero civico, vuoi proseguire comunque?','Attenzione', function () {
      confirmCurrentPositionAddress()
    });*/
    f7app.dialog.create({
      title: 'Attenzione',
      text: 'Sembra che nell\'indirizzo specificato manchi il numero civico',
      buttons: [
        { text: 'Annulla'},
        { text: 'Continua senza il civico',  },
      ],
      onClick: function (dialog, index) {
        if(index === 1){
          confirmCurrentPositionAddress()
        }
      },
      verticalButtons: true,
      }).open();
	}
});

function confirmCurrentPositionAddress() 
{ 
  let _current_address = mapManager.Current_Address;
  let _current_location =  mapManager.Current_Location;

  add_address(_current_address, _current_location, function()
  {
    confirm_current_location();
  });
}

$$(document).on('click', '#reload-current-location', function(e)
{
  mapManager.Get_Current_Location();
});

$$(document).on('submit', '#form-current-address-input', function(e)
{
  e.preventDefault();

  return false;
});

function add_address(address, location, callback)
{
  let _lat = location.lat;
  let _lng = location.lng;

  let _request_params = 
  {
    'address' : address,
    'latitude' : _lat,
    'longitude' : _lng,
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_add_address.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        let _new_address_id = parseFloat(data.data.id);

        mapManager.Current_Address_Id = _new_address_id;
  
        if(typeof callback !== 'undefined')
        {
          callback();
        }
      }
      else
      {
        f7app.dialog.alert(data.msg, "");
      }
    },
    error: function() 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function confirm_current_location()
{
  mapManager.Street_Number_Is_Set=false
  ViewHome.router.navigate('/restaurants/');

  console.log(mapManager);
}

// Order repeat

$$(document).on('click', '[data-order-id]', function (e) 
{
  let _this = $$(this);
  let _order_id = parseInt(_this.attr('data-order-id'));

  repeat_order(_order_id)   
});


// condividi ristoranti
$$(document).on('click', '.condividi', function (e) 
{
  share_restaurant(parseFloat(appCore.restaurant_id),appCore.current_restaurant_data.name);
});
          
// --- Login, logout, signup, confirm account, delete account

$$(document).on('click', '#login', function (e) 
{
  e.preventDefault();

  log_in();
});

$$(document).on('click', '#signup-save', function (e) 
{
  e.preventDefault();

  register();
});

$$(document).on('click', '#confirm-profile', function (e) 
{
  e.preventDefault();

  confirm_profile();
});

$$(document).on('click', '#confirm-phone', function (e) 
{
  e.preventDefault();

  confirm_phone();
});

$$(document).on('click', '#logout', function (e) 
{
  log_out();
});

$$(document).on('click', '#delete-account', function (e) 
{
  e.preventDefault();

  f7app.dialog.confirm("Si è sicuri di proseguire con l'eliminazione dell'account?", "Eliminazione account", function () 
  {
    f7app.dialog.confirm("Confermare l'eliminazione?", "Eliminazione account", function () 
    {
      delete_account();
    });
  });
});

$$(document).on('click', '#request-reset-password', function (e) 
{
  let _email = $$('#signin-email').val().trim();

  if(_email == "")
  {
    f7app.dialog.alert("Inserire l'indirizzo email!", "");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
    'email' : _email
  };

  f7app.request(
  {
    url: api_url + 'request_reset_password.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log('reset password', data);

      if(data.success == true)
      {
        f7app.dialog.alert("Richiesta inviata con successo! A breve riceverai un'email per completare la reimpostazione della password.", "Info");
      }
      else
      {
        f7app.dialog.alert("Impossibile richiedere la reimpostazione della password! E' stato inserito un indirizzo email valido?", "Attenzione");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});



function log_in()
{
  let _email = $$('#signin-email').val().trim();
  let _password = $$('#signin-password').val();
  //let _recaptcha_response = $$('[name ="g-recaptcha-response"]').val().trim();

  if(_email == "")
  {
    f7app.dialog.alert("Inserire l'indirizzo email!", "");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  if(_password == "")
  {
    f7app.dialog.alert("Inserire la password!");

    return;
  }

  /*
  if(_recaptcha_response == "")
  {
    f7app.dialog.alert("Confermare di non essere un robot! :D", "");

    return;
  }
  */

  let _request_params = 
  {
    'email' : _email,
    'password' : _password,
    'user_type' : 'Customer',
    //'recaptcha_response' : _recaptcha_response,
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'login.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("login", data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        let _sessionToken = data.data.session_token;

        // Logga il token di session
        
        appUtilities.setParam('session-token', _sessionToken);

        appCore.session_token = _sessionToken;

        user_logged = true;

        // Torna alla home

        f7app.tab.show('#view-home', '#tab-home', true);

        ViewHome.router.refreshPage();

        // Ricerca eventuali ordini in sospeso

        load_current_orders();

        // Ricarica la view del profilo con i dati dell'utente

        ViewProfile.router.navigate('/profile/');
      }
      else
      {
        if(data.data !== null)
        {
          let _status = parseInt(data.data.status);

          if(_status == 0)
          {
            ViewProfile.router.navigate('/confirm-profile/');
          }
        }
        else
        {
          f7app.dialog.alert("Impossibile accedere: " + data.msg, "");
        }
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function confirm_profile()
{
  let _otp = $$('#confirm-account-otp').val();

  if(_otp.length != 6)
  {
    f7app.dialog.alert("Codice temporaneo inserito non valido!", "");

    return;
  }

  let _request_params = 
  {
    'otp' : _otp,
  };

  f7app.request(
  {
    url: api_url + 'confirm_account.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("confirm account", data);

      let _success = parseFloat(data.success);

      console.log(_success);

      if(_success == 1)
      {
        let _sessionToken = data.data.session_token;

        // Logga il token di session

        appUtilities.setParam('session-token', _sessionToken);

        appCore.session_token = _sessionToken;

        user_logged = true;

        fbq('track', 'CompleteRegistration');

        // Torna alla home

        f7app.tab.show('#view-home', '#tab-home', true);

        ViewHome.router.refreshPage();

        // Ricarica la view del profilo con i dati dell'utente

        ViewProfile.router.navigate('/profile/');
      }
      else
      {
        f7app.dialog.alert("Impossibile completare la creazione del profilo: " + data.msg, "");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function confirm_phone()
{
  let _otp = $$('#confirm-phone-otp').val();

  if(_otp.length != 6)
  {
    f7app.dialog.alert("Codice temporaneo inserito non valido!", "");

    return;
  }

  let _request_params = 
  {
      'otp' : _otp,
      'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_confirm_phone.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("customer confirm phone", data);

      let _success = parseFloat(data.success);
      let _msg = data.msg;

      if(_success == 1)
      {
        let _new_phone = data.data.new_phone;

        appCore.current_profile_data.phone_number = _new_phone;
  
        ViewProfile.router.navigate('/profile/');

        f7app.dialog.alert("Profilo aggiornato con successo!", "");
      }
      else
      {
        f7app.dialog.alert(data.msg, "");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function register()
{
  let _accept_terms_checked = ($$('#accept-terms:checked').length > 0);
  let _email = $$('#signup-email').val().trim();
  let _password = $$('#signup-password').val().trim();
  let _name = $$('#signup-name').val().trim();
  let _surname = $$('#signup-surname').val().trim();
  let _phone = $$('#signup-phone').val().trim();
  let _user_type = "Customer";
  let _device_type = "Web";
  let _firebase_token = "";
  let _latitude = 0;
  let _longitude = 0;

  if(_email == "")
  {
    f7app.dialog.alert("Inserire un indirizzo email!", "Attenzione!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  if(_password == "")
  {
    f7app.dialog.alert("Inserire la password!", "Attenzione!");

    return;
  }

  if(_name == "")
  {
    f7app.dialog.alert("Inserire il nome!", "Attenzione!");

    return;
  }

  if(_surname == "")
  {
    f7app.dialog.alert("Inserire il cognome!", "Attenzione!");

    return;
  }

  if(_phone == "")
  {
    f7app.dialog.alert("Inserire il numero di telefono!", "Attenzione!");

    return;
  }

  if(_phone.length < 9)
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  if(_accept_terms_checked == false)
  {
    f7app.dialog.alert("Accettare i termini e le condizioni prima di proseguire!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
    'email' : _email,
    'password' : _password,
    'user_type' : _user_type,
    'device_type' : _device_type,
    'firebasetoken' : _firebase_token,
    'latitude' : _latitude,
    'longitude' : _longitude,
    'name' : _name,
    'surname' : _surname,
    'phone_number' : _phone,
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'register.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        ViewProfile.router.navigate('/confirm-profile/');
      }
      else
      {
        f7app.dialog.alert(data.msg, "");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function log_out()
{
  appUtilities.removeParam('session-token');

  window.location.href = app_url;
}

function delete_account()
{
  let _request_params = 
  {
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_delete_account.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("delete account", data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        f7app.dialog.alert("Arrivederci!", "", function () 
        {
          appUtilities.removeParam('session-token');
      
          window.location.href = app_url;
        });
      }
      else
      {
        f7app.dialog.alert(data.msg, "");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

// --- Checkout ---

$$(document).on('click', '#goto-checkout', function (e) 
{
  let _cart_items_count = $$('.cart-item').length;

  if(_cart_items_count == 0)
  {
    f7app.dialog.alert("Nessun articolo inserito nell'ordine!", "Attenzione");
  }
  else
  {
    ViewHome.router.navigate('/checkout/');
  }
});

$$(document).on('click', '#send-order', function (e) 
{
  send_order();
});

$$(document).on('click', '#fast-way', function (e) 
{
  $$('#pre-order-time-input').css('display', 'none');

  appCore.current_order_data.delivery_mode = 'fast-way';
});

$$(document).on('click', '#pre-order', function (e) 
{
  $$('#pre-order-time-input').css('display', 'block');

  appCore.current_order_data.delivery_mode = 'pre-order';
});

$$(document).on('change', '#pre-order-time', function (e) 
{
  let _pre_order_time_selected = $$(this).val();

  appCore.current_order_data.pre_order_selection = _pre_order_time_selected;
});

$$(document).on('click', '#payment-method-cash', function (e) 
{
  appCore.current_order_data.payment_mode = 'cash';
});

$$(document).on('click', '#payment-method-credit-card', function (e) 
{
  appCore.current_order_data.payment_mode = 'credit_card';
});

$$(document).on('change', '#instructions', function (e) 
{
  let _instructions = $$(this).val();

  console.log("instructions", _instructions);
  
  appCore.current_order_data.instructions = _instructions;
});

function send_order()
{
  let _order_date = document.getElementById('order-date').value;
  let _fastWay_checked = ($$('#fast-way:checked').length > 0);
  let _preOrder_checked = ($$('#pre-order:checked').length > 0);
  let _paymentMethodCash_checked = ($$('#payment-method-cash:checked').length > 0); 
  let _pre_order_time_selected=($$('#pre-order-time').val()!="")
  let _time_limit = (_fastWay_checked ? '' : $$('#pre-order-time').val());

  /*
  NOTA IMPORTANTE! 

  La variabile '_time_limit_status' deve essere "pre_order" e non "pre-order"
  perché l'app del ristorante si triggera sul valore "pre_order" per
  decidere se visualizzare l'orario di consegna selezionato o no!!
  */

  let _time_limit_status = (_fastWay_checked ? 'fast_way' : 'pre_order'); 
  let _address_id = mapManager.Current_Address_Id;
  let _instruction = $$('#instructions').val();
  let _restaurant_id = parseFloat(appCore.restaurant_id);
  let _order_preference = 'Consegna a domicilio';
  let _payment_type = (_paymentMethodCash_checked ? 'cash' : 'credit_card');

  if($$("#closed").length != 0  )
  {
    f7app.dialog.alert("Questo ristorante non è disponibile nella data selezionata, ti preghiamo di modificare il giorno di consegna. Puoi visualizzare i giorni di chiusura e gli orari di consegna nella pagina del menù del ristorante, Grazie!", "Attenzione");

    return;
  }
  
  if(_preOrder_checked  && !_pre_order_time_selected){
	  
	  f7app.dialog.alert("Per inviare l'ordine seleziona una fascia oraria di consegna", "Attenzione");

    return;
  }

  if(_address_id <= 0 || isNaN(_address_id))
  {
    f7app.dialog.alert("Specificare un indirizzo di consegna!", "Attenzione");

    return;
  }
  
  if(parseFloat($$('#items-total').html()) < appCore.current_restaurant_data.min_order_amount)
  {
    f7app.dialog.alert("Per ordinare da questo locale il costo dei prodotti ordinati deve essere uguale o superiore all'ordine minimo. Il valore del minimo d'ordine è indicato nella pagina del menu del ristorante, Grazie ", "Attenzione");

    return;
  }

  if(user_logged == false)
  {
    // Va alla schermata di login

    f7app.tab.show('#view-profile', '#tab-profile', true);

    return;
  }

	var parts = _order_date.split('-');

	_order_date = parts[2] + "-" + parts[1] + "-" + parts[0];

  let _request_params = 
  {
    'time_limit' : _time_limit,
    'time_limit_status' : _time_limit_status,
    'address_id' : _address_id,
    'instruction' : _instruction,
    'restaurant_id' : _restaurant_id,
    'order_preference' : _order_preference,
    'payment_type' : _payment_type,
    'order_date': _order_date,
    'onesignal_player_id': window.onesignalPlayerId,
    'session_token' : appCore.session_token
  };

  f7app.dialog.preloader();

  f7app.request(
  {
    url: api_url + 'customer_place_order.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (placeOrderData) 
    {
      f7app.dialog.close();

      console.log("place order data", placeOrderData);

      let _success = parseFloat(placeOrderData.success);
      let _msg = placeOrderData.msg;

      if(_success == 1)
      {
        let _order_payment_type = placeOrderData.data.payment_type;

        if(_order_payment_type == "cash")
        {
          // Pagamento in contanti

          fbq('track', 'Purchase', {currency: "EUR", value: 1});

          gtag_report_conversion();

          f7app.dialog.alert("Ordine inviato con successo!", "Ordine inviato", function()
          {
            window.location.href = app_url;
          });
        }
        else
        {
          // Pagamento con la carta di credito

          appCore.paying_order = true;

          appCore.order_id = placeOrderData.data.order_id;

          ViewHome.router.navigate('/stripe-payment/');
        }
      }
      else
      {
        f7app.dialog.alert(_msg, "");
      }
    },
    error: function () 
    {
      f7app.dialog.close();

      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

// --- Stripe payment ---

$$(document).on('click', '#stripe-cancel-order', function (e) 
{
  f7app.dialog.confirm
  (
    "Tornare al carrello?", "",
    function() // Confirm
    {
      // Annulla l'ordine

      var _request_params = 
      {
        'order_id' : appCore.order_id,
        'session_token' : appCore.session_token
      };
    
      f7app.request(
      {
        url: api_url + 'customer_cancel_order.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (cancelOrderData) 
        {
          console.log("cancel order data", cancelOrderData);

          let _success = parseFloat(cancelOrderData.success);
          let _msg = cancelOrderData.msg;

          if(_success == 1)
          {
            if(appCore.thereIs_order_to_pay == true)
            {
              ViewHome.router.back("/current-position-address/",
              {
                ignoreCache: true,
                force: true
              });
            }
            else
            {
              ViewHome.router.back();
            }

            // Ricerca eventuali ordini in sospeso

            load_current_orders();
          }
          else
          {
            f7app.alert(_msg);
          }
        }
      });
    }, 
    function() // Cancel
    {
      // Nothing to do
    })
});

// --- Current order ---

function load_order_to_pay(callback)
{
  var _request_params = 
  {
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_order_to_pay.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      let _success = parseFloat(data.success);
      let _msg = data.msg;

      console.log("order to pay data", data);

      let _there_is_order_to_pay = false;

      if(_success == 1)
      {
        _there_is_order_to_pay = true;

        appCore.order_id = data.order_id;

        ViewHome.router.navigate('/stripe-payment/');

        f7app.tab.show('#view-home', '#tab-home', true);
      }
      
      if(typeof callback !== 'undefined')
      {
          callback(_there_is_order_to_pay);
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

function load_current_orders()
{
  var _request_params = 
  {
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_current_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("customer current orders data",  data);

      let _tab_current_orders_element = $$('<a id="tab-current-orders" href="#view-current-orders" class="tab-link">' +
        '<img height="25px" src="./static/images/order.png" class="tab-image"/>' +
		    '<img height="25px" src="./static/images/orderSelected.png"  class="tab-image-selected"/>' + 
        '<span class="tabbar-label" style="font-weight: bold; font-size: 1.2em;">Ordini</span>' +
        '</a>'
      );
    
      if(data.data === null)
      {
        // Nessun ordine in sospeso

        f7app.tab.show('#view-home', '#tab-home', true);
      }
      else
      {
        if(data.data.length > 0)
        {
          // C'è almeno un ordine in sospeso

          appCore.waiting_orders = data;

          if($$('#tab-current-orders').length == 0)
          {
            _tab_current_orders_element.insertAfter('#tab-home');
          }

          // Carica subito i dati dell'ordine in sospeso
          
          periodic_current_orders_check();
    
          // Apre la tab degli ordini correnti

          f7app.tab.show('#view-current-orders', '#tab-current-orders', true);
        }
        else
        {
          // Nessun ordine in sospeso

          f7app.tab.show('#view-home', '#tab-home', true);
        }
      }

      // Avvia il controllo periodico degli ordini

      setInterval(function()
      { 
        periodic_current_orders_check();
      },
      10000);
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

function periodic_current_orders_check()
{
  // Controllo periodico ordini correnti

  if(appCore.session_token.trim() == "")
  {
    // Utente non loggato

    return false;
  }

  var _request_params = 
  {
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'customer_current_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (currentOrdersData) 
    {
      console.log("customer current orders data", currentOrdersData);

      let _page_obj = $$('.page[data-name="current-orders"]');

      if(_page_obj.length == 0)
      {
        return;
      }

      let _page = $$('.page[data-name="current-orders"]')[0].f7Page; 
      let _self = _page.$pageEl[0].f7Component; 

      if(currentOrdersData.data != null)
      {
        appCore.previous_orders_count = currentOrdersData.data.length;

        _self.$setState(
        { 
          data: currentOrdersData.data
        });
      }
      else
      {
        if(appCore.previous_orders_count == -1)
        {
          appCore.previous_orders_count = 0;
        } 
        
        if(appCore.previous_orders_count > 0)
        {
          window.location.href = app_url;
        }
      }
    },
    error: function () 
    {
    }
  });
}

// --- Profile ---

$$(document).on('click', '#update-profile', function (e) 
{
  let _name = $$('#profile-name').val().trim();
  let _surname = $$('#profile-surname').val().trim();
  let _phoneNumber = $$('#profile-phone-number').val().trim();
  let _email = $$('#profile-email').val().trim();

  if(_name == "")
  {
    f7app.dialog.alert("Inserire un nome valido!", "Attenzione!");

    return;
  }

  if(_surname == "")
  {
    f7app.dialog.alert("Inserire un cognome valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber == "")
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber.length < 9)
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
      'name' : _name,
      'surname' : _surname,
      'phone_number' : _phoneNumber,
      'email' : _email,
      'session_token' : appCore.session_token,
      'user_type' : 'Customer'
  };

  f7app.request(
  {
    url: api_url + 'edit_customer_profile.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);
      let _msg = data.msg;

      appCore.current_profile_data.name = _name;
      appCore.current_profile_data.surname = _surname;
      appCore.current_profile_data.email = _email;

      if(appCore.current_profile_data.phone_number != _phoneNumber)
      {
        // Apre la schermata di conferma numero di telefono

        ViewProfile.router.navigate('/confirm-phone/');
      }
      else
      {
        f7app.dialog.alert("Profilo aggiornato con successo!", "");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});

// --- Device and App data ---

function get_current_app_version()
{
  var _request_params = 
  {
    'app_type' : app_type
  };

  f7app.request(
  {
    url: api_url + 'current_app_version.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (currentAppVersionData) 
    {
      let _success = parseFloat(currentAppVersionData.success);

      console.log("current app version data", currentAppVersionData);

      if(_success == 1)
      {
        let _current_app_version = currentAppVersionData.data.app_version;
        let _last_update_version = appUtilities.getParam('last-update-version');

        if(_last_update_version == _current_app_version)
        {
            // E' già stata gestita questa versione

            return true;
        }

        if(_current_app_version != app_version)
        {
          // Salva in locale che si sta gestendo questa nuova versione

          appUtilities.setParam('last-update-version', _current_app_version);

          // Update app

          if
          (
            Framework7.device.android == true
            || Framework7.device.cordova == true
            || Framework7.device.ios == true
            || Framework7.device.ipad == true
            || Framework7.device.iphone == true
            || Framework7.device.ipod == true
            || Framework7.device.macos == true
            || Framework7.device.phonegap == true
            || Framework7.device.webView == true
            || Framework7.device.webview == true
          )
          {
            // App nativa

            /*
            alert(`
              È presente una versione piu recente dell'applicazione.
              Ti consigliamo di effettuare l'aggiornamento in quanto alcune funzionalità della versione corrente potrebbero non funzionare, Grazie.
              `, "Aggiornamento");
            */
          }
          else
          {
            // App web

            alert(`
              E' presente un nuovo aggiornamento dell'applicazione. 
              Chiudendo questo messaggio verrà installato il nuovo aggiornamento e riavviata l'applicazione.
              `, "Aggiornamento");
        
            if ('serviceWorker' in navigator) 
            {
              // Cancella la cache dell'applicazione
  
              caches.keys().then(function(cacheNames) 
              {
                cacheNames.forEach(function(cacheName) 
                {
                  caches.delete(cacheName);
                });
              });
  
              // Ricarica la pagina
  
              window.location.href = app_url;
            }
            else
            {
              // Impossibile cancellare la cache
  
              alert("Si è verificato un errore durante l'aggiornamento: si prega di contattare il supporto tecnico.");
            }
          }
        }
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

function get_device_type()
{
  let _ret = '';

  if(Framework7.device.android == true)
  {
    _ret = 'android';
  }
  else if(Framework7.device.androidChrome == true)
  {
    _ret = 'android-chrome';
  }
  else if(Framework7.device.cordova == true)
  {
    _ret = 'cordova';
  }
  else if(Framework7.device.desktop == true)
  {
    _ret = 'desktop';
  }
  else if(Framework7.device.edge == true)
  {
    _ret = 'edge';
  }
  else if(Framework7.device.electron == true)
  {
    _ret = 'electron';
  }
  else if(Framework7.device.firefox == true)
  {
    _ret = 'firefox';
  }
  else if(Framework7.device.ie == true)
  {
    _ret = 'ie';
  }
  else if(Framework7.device.ios == true)
  {
    _ret = 'ios';
  }
  else if(Framework7.device.ipad == true)
  {
    _ret = 'ipad';
  }
  else if(Framework7.device.iphone == true)
  {
    _ret = 'iphone';
  }
  else if(Framework7.device.ipod == true)
  {
    _ret = 'ipod';
  }
  else if(Framework7.device.macos == true)
  {
    _ret = 'macos';
  }
  else if(Framework7.device.nwjs == true)
  {
    _ret = 'nwjs';
  }
  else if(Framework7.device.phonegap == true)
  {
    _ret = 'phonegap';
  }
  else if(Framework7.device.webView == true || Framework7.device.webView == true)
  {
    _ret = 'webview';
  }
  else if(Framework7.device.windows == true)
  {
    _ret = 'windows';
  }

  return _ret;
}

function send_app_and_device_data()
{
  var _request_params = 
  {
    'session_token' : appCore.session_token,
    'app_version' : app_version,
    'os_version' : Framework7.device.os,
    'device_type' : device_type
  };

  f7app.request(
  {
    url: api_url + 'device_app_data.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
    },
    error: function () 
    {
    }
  });
}

function repeat_order(order_id)
{
  var _request_params = 
  {
    'order_id' : order_id,
    'session_token' : appCore.session_token
  };
	 
  f7app.request(
  {
    url: api_url + 'cart/copy_cart_item.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (copyOrderData) 
    {
      console.log("Copy order data: ", copyOrderData);

      let _success = parseFloat(copyOrderData.success);

      if(_success == true)
      {
        appCore.restaurant_id = copyOrderData.data.restaurant_id;	
        
        mapManager.Current_Address = copyOrderData.data.address;
        mapManager.Current_Address_Id = copyOrderData.data.address_id; 

        mapManager.Get_LatLng_By_Address(copyOrderData.data.address, function(location)
        {         
          mapManager.Current_Location = location;
        });
      
        if(mapManager.Current_Address_Id == 0)
        {
          f7app.dialog.alert('Impossibile trovare l\'indirizzo di consegna! Riprovare di nuovo.', "Attenzione");

          return;
        }

        var _request_params = 
        {
          'latitude' : copyOrderData.data.latitude,
          'longitude' : copyOrderData.data.longitude,
          'restaurant_id' : appCore.restaurant_id,
          'session_token' : appCore.session_token
        };

        f7app.request(
        {
          url: api_url + 'customer_restaurant_details.php',
          dataType: 'json',
          data: _request_params,
          method: 'POST',
          cache: false,
          crossDomain: true,
          success: function (data) 
          {
            console.log("restaurant details data", data);

            appCore.current_restaurant_data.id = data.data.restaurant_id;
            appCore.current_restaurant_data.name = data.data.resturant_name;
            appCore.current_restaurant_data.address = data.data.address;
            appCore.current_restaurant_data.image = data.data.image;
            appCore.current_restaurant_data.cover_image = data.data.cover_image;
            appCore.current_restaurant_data.latitude = data.data.Latitude;
            appCore.current_restaurant_data.longitude = data.data.Longitude;
            appCore.current_restaurant_data.phone = data.data.phonenumber;
            appCore.current_restaurant_data.opening_hours = data.data.opening_hours;
            appCore.current_restaurant_data.partner_info = data.data.partner_info;
            appCore.current_restaurant_data.closing_day = data.data.closing_day;
            appCore.current_restaurant_data.online_payment_enabled = data.data.online_payment_enabled;
            appCore.current_restaurant_data.cash_payment_enabled = data.data.cash_payment_enabled;
            appCore.current_restaurant_data.min_order_amount = data.data.min_order_amount;
            appCore.current_restaurant_data.shipping_cost = data.data.shipping_cost;
            appCore.current_restaurant_data.distance = data.data.distance;

            ViewHome.router.navigate('/checkout/');

            if(typeof callback !== 'undefined')
            {
              callback();
            }
          },
          error: function () 
          {
            f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
          }
        });
      }
      else
      {
        f7app.dialog.alert(copyOrderData.msg, "Errore");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}


$$(document).on('page:init', function (e) 
{
  if (Framework7.device.cordova) {
    $$('.web-footer-link2').remove()
  } 
});




function share_restaurant( restaurant_id, restaurant_name) { 
  var shared_url=window.webapp_url+'?ristorante='+restaurant_id
  if (Framework7.device.cordova ){
    // this is the complete list of currently supported params you can pass to the plugin (all optional)
    var options = {
      message: 'Dai un\'occhiata al menu del ristorante ' + restaurant_name+ ', a domicilio con Jack&B: \n', // not supported on some apps (Facebook, Instagram)
      subject: '', // fi. for email
      url: shared_url
    };

    var onSuccess = function(result) {
        };

    var onError = function(msg) {
       };
    

    window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
  
  
  }else{
    console.log("non condivido target: ", get_device_type())
    navigator.share({
      'title': '',
      'text': 'Dai un\'occhiata al menu del ristorante ' + restaurant_name+ ', a domicilio con Jack&B: \n ',
      'url': shared_url
    }).then(function() {
      //console.log('Successful share');
    }).catch(function(error) {
      //console.log('Error sharing:', error)
    });
  }
}
