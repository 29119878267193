import $$ from 'dom7';
import Template7 from 'template7';

import NotFoundPage from '../pages/404.f7.html';

import HomePage from '../pages/home.f7.html';
import HelpPage from '../pages/help.f7.html';
import AppInfoPage from '../pages/app-info.f7.html';
import TermsPage from '../pages/terms.f7.html';
import RestaurantsPage from '../pages/restaurants.f7.html';
import RestaurantProductsPage from '../pages/restaurant-products.f7.html';
import CartItemDetailsPage from '../pages/cart-item-details.f7.html';
import CurrentOrdersPage from '../pages/current-orders.f7.html';
import CheckoutPage from '../pages/checkout.f7.html';
import LoginPage from '../pages/login.f7.html';
import SignUpPage from '../pages/signup.f7.html';
import ConfirmProfilePage from '../pages/confirm-profile.f7.html';
import ConfirmPhonePage from '../pages/confirm-phone.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import CurrentPositionAddressPage from '../pages/current-position-address.f7.html';
import StripePaymentPage from '../pages/stripe-payment.f7.html';

var routes = 
[
  /*
  {
    path: '(.*)',
    component: NotFoundPage,
  },
  */

  // /index.html
  {
    path: '/index.html',
    component: HomePage,
    on: 
    {
      pageInit: function (event, page) 
      {
        ViewLogin = f7app.views.get('#view-login');
        ViewHome = f7app.views.get('#view-home');
        ViewProfile = f7app.views.get('#view-profile');

        $$('.toolbar').show();

        f7app.tab.show('#view-home', '#tab-home', true);
      },
      pageAfterIn: function (event, page) 
      {
      },
    }
  },

  // /
  {
    path: '/',
    component: HomePage,
    on: 
    {
      pageInit: function (event, page) 
      {
        ViewLogin = f7app.views.get('#view-login');
        ViewHome = f7app.views.get('#view-home');
        ViewProfile = f7app.views.get('#view-profile');

        $$('.toolbar').show();
        
        f7app.tab.show('#view-home', '#tab-home', true);
      },
      pageAfterIn: function (event, page) 
      {
      },
    }
  },

  // /help/
  {
    path: '/help/',
    component: HelpPage,
  },

  // /app-info/
	{
		path: '/app-info/',
		component: AppInfoPage,
  },
  
  // /terms/
  {
    path: '/terms/',
    component: TermsPage,
  },

  // /login/
  {
    path: '/login/',
    component: LoginPage,
  },

  // /signup/
  {
    path: '/signup/',
    component: SignUpPage,
  },

  // /confirm-profile/
  {
    path: '/confirm-profile/',
    component: ConfirmProfilePage
  },

  // /confirm-phone/
  {
    path: '/confirm-phone/',
    component: ConfirmPhonePage
  },
    
  // /profile/
  {
    path: '/profile/',
    component: ProfilePage
  },
  
  // /current-position-address/
  {
		path: '/current-position-address/',
		component: CurrentPositionAddressPage,
  },

  // /current-orders/
  {
		path: '/current-orders/',
		component: CurrentOrdersPage,
  },
  
  // /checkout/
  {
    path: '/checkout/',
    component: CheckoutPage,
  },

  // /restaurants/
  {
    path: '/restaurants/',
    component: RestaurantsPage,
  },

  // /restaurants-products/
  {
    path: '/restaurants-products/',
    component: RestaurantProductsPage,
  },

  // /cart-item-details/
	{
		path: '/cart-item-details/',
		component: CartItemDetailsPage,
  },

  // /stripe-payment/
	{
		path: '/stripe-payment/',
		component: StripePaymentPage,
  },
];

export default routes;