
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data = null, cartItemData = null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      var app = self.$app;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      f7app.toolbar.hide('.toolbar', true);
      document.arrive("#item-totals", function () {
        let _page_content_height = $$('.page[data-name="cart-item-details"] > .page-content').height();

        let _window_height = window.innerHeight;

        let _navbar_height = $$('.navbar').height();

        let _item_totals_height = $$('#item-totals').height();

        let _item_image_background = $$('#item-image-background').height();

        let _item_details_height = _page_content_height - _item_totals_height - 15;

        console.log("page content height " + _page_content_height + " navbar height " + _navbar_height + " items total height " + _item_totals_height);
        $$('#item-details').css('max-height', _item_details_height + "px");
      });
      var _cart_item_row_id = _route.query.id;
      var _request_params = {
        'cart_item_row_id': _cart_item_row_id,
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'cart/cart_item_details.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (cartItemDetailsData) {
          console.log("cart item details", cartItemDetailsData);

          _self.$setState({
            data: cartItemDetailsData.data,
            cartItemData: {
              id: cartItemDetailsData.data.id
            }
          });
        },
        error: function () {
          app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var self = this;
      var app = self.$app;
    }
  },
  id: 'b0ae2f898e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=cart-item-details style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><!--\r\n		<div class="navbar" >\r\n\r\n            <div class="navbar-bg" style="background:#eeb42c;"></div>\r\n\r\n            <div class="navbar-inner sliding">\r\n        \r\n                <div class="left">\r\n					<a href="#" data-cart-item-action="delete-cart-item">\r\n						<img src="./static/images/tasto_back.png" style=" width:40px; margin:10px;"/>	\r\n					</a>\r\n                </div>\r\n        \r\n                <div class="title"></div>\r\n        \r\n                <div class="right">\r\n                </div>\r\n        \r\n            </div>\r\n    \r\n        </div>\r\n        --><!-- Scrollable page content--><div class=page-content style="padding-top: 0px;">';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="card skeleton-text skeleton-effect-wave"><div class=card-header>Card Header</div><div class="card-content card-content-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit.</div><div class=card-footer>Card Footer</div></div><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <input id=cart-item-row-id type=hidden value=';
          r += c(ctx_2.data.id, ctx_2);
          r += '><div id=item-details style="max-height:50px; overflow: scroll;">';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.image.includes('default_blank_item.png') == false && this.data.image.trim() != ''", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div id=item-image-background class=block style="margin-bottom: 10px; margin-top: 0px; height: 200px; width: 100%; background-size: cover;background-position: center; background-image:url(\'';
              r += Template7Helpers.js.call(ctx_3, 'img_url', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += c(ctx_3.data.image, ctx_3);
              r += '\')"></div><div class="card card-outline" style="z-index:2000; margin-top: 20px;">';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div id=item-image-background class=block style="margin-bottom: 10px; margin-top: 0px; height: 0px;"></div><div class="card card-outline">';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=card-header style="justify-content: center; font-weight: bold;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - ';
          r += c(ctx_2.data.item_price, ctx_2);
          r += '&euro;</div>';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.description != ''", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=card-content style="text-align:center; justify-content: center; padding: 10px;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.data.description, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><!-- Dettagli articolo --> ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.is_customizable == 1", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<!-- Aggiunte articolo --><div class="card card-outline"><div class=card-header style="font-weight: bold;">Aggiunte</div><div class=card-content><div class=list><ul>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.data.ingredients, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li><div class=row>';
                  r += Template7Helpers.js_if.call(ctx_4, "this.ingredient_qty > 0", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=col-80><a href=# class="item-link item-content" data-cart-ingredient-action=add-cart-ingredient data-cart-ingredient-id=';
                      r += c(ctx_5.ingredient_id, ctx_5);
                      r += '>';
                      r += Template7Helpers.js_if.call(ctx_5, "this.ingredient_qty > 0", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=item-media><span class=badge style="background-color: #b11733; width: 20px; height: 20px;">';
                          r += c(ctx_6.ingredient_qty, ctx_6);
                          r += '</span></div>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=item-media style="display: none;"><span class=badge>';
                          r += c(ctx_6.ingredient_qty, ctx_6);
                          r += '</span></div>';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '<div class="item-inner no-chevron"><div class=item-title>';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.ingredient_name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div><div class=item-after><span>+ ';
                      r += c(ctx_5.ingredient_price, ctx_5);
                      r += '&euro;</span></div></div></a></div>';
                      r += Template7Helpers.js_if.call(ctx_5, "this.ingredient_qty > 0", {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=col-100><a href=# class="item-link item-content" data-cart-ingredient-action=add-cart-ingredient data-cart-ingredient-id=';
                      r += c(ctx_5.ingredient_id, ctx_5);
                      r += '>';
                      r += Template7Helpers.js_if.call(ctx_5, "this.ingredient_qty > 0", {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=item-media><span class=badge style="background-color: #b11733; width: 20px; height: 20px;">';
                          r += c(ctx_6.ingredient_qty, ctx_6);
                          r += '</span></div>';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class=item-media style="display: none;"><span class=badge>';
                          r += c(ctx_6.ingredient_qty, ctx_6);
                          r += '</span></div>';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '<div class="item-inner no-chevron"><div class=item-title>';
                      r += Template7Helpers.escape.call(ctx_5, ctx_5.ingredient_name, {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div><div class=item-after><span>+ ';
                      r += c(ctx_5.ingredient_price, ctx_5);
                      r += '&euro;</span></div></div></a></div>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, "this.ingredient_qty > 0", {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=col-20><a href=# data-cart-ingredient-action=remove-cart-ingredient data-cart-ingredient-id=';
                      r += c(ctx_5.ingredient_id, ctx_5);
                      r += '><img height=25px src=./static/images/delete-red.png style="margin-left: 10px; margin-top: 10px;"></a></div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div id=item-totals><!-- Totale riga carrello --><div class="card card-outline"><div class="card-content card-content-padding"><div class=list><ul><!-- Totale articoli --><li class=item-content><div class=item-inner><div class=item-title style="font-weight: bold;">Quantità: <span id=cart-item-qty style=display:none>';
          r += c(ctx_2.data.item_qty, ctx_2);
          r += '</span></div><div class=item-after><div id=cart-item-qty-stepper class="stepper stepper-large stepper-round stepper-fill stepper-init" data-value-el=#cart-item-qty><div class=stepper-button-minus data-cart-item-action=increase-cart-item-qty style="border-radius:100%; margin:3px;background:#eeb42c;border:0px;"></div><div class=stepper-input-wrap style=border:0px><input type=text readonly=readonly min=1 step=1 value=';
          r += c(ctx_2.data.item_qty, ctx_2);
          r += ' style=color:black;></div><div class=stepper-button-plus data-cart-item-action=decrease-cart-item-qty style="border-radius:100%; margin:3px;background:#eeb42c;border:0px;"></div></div></div><div class=item-after>';
          r += c(ctx_2.data.item_total, ctx_2);
          r += '&euro;</div></div></li></ul></div></div></div><!-- Rimozione articolo nel carrello --><div id=add_delete_button class="card card-outline" style="border: none;"><div class="card-content card-content-padding"><div class=row><div class=col-100><button id=back-to-cart class="col button button-large button-fill" style="margin-bottom: 10px;">Conferma</button></div></div><div class=row><div class=col-100 style="text-align: center; font-weight: bold; font-size: 1.1em;"><a href=# data-cart-item-action=delete-cart-item>Elimina</a></div></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    